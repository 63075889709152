import { Injectable } from '@angular/core';
import {Meta, Title} from "@angular/platform-browser";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class SeoService {

  constructor(
    private titleService: Title,
    private metaService: Meta
  ) { }

  updateTags(description: string, keywords:Array<string> = []) {
    this.metaService.removeTag('description');
    this.metaService.removeTag('keywords');
    this.metaService.addTags([
      {name: 'description', content: description},
      {name: 'keywords', content: keywords.join(', ')}
    ]);
  }

  updateTitle(title: string) {
    this.titleService.setTitle(environment.mainTitle + ' | ' + title);
  }
}
