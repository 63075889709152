import { Component, Input, OnInit } from '@angular/core';
import { BannerItem } from '../../models/bannerItem.model';

@Component({
  selector: 'app-carousel',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
})
export class BannerComponent implements OnInit {
  @Input() items: BannerItem[];
  @Input() showNavigators: boolean = true;

  public interval: number = 15000;
  constructor() {}

  ngOnInit(): void {}
}
