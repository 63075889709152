import { NgModule } from '@angular/core';
import { PrimengModule } from './primeng.module';
import { AppPagebaseComponent } from '../core/pagebase/app.pagebase.component';
import { TranslateModule } from '@ngx-translate/core';
import { AppBaseComponent } from '../core/pagebase/app.base.component';
import { BannerComponent } from '../core/banner/banner/banner.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    AppPagebaseComponent,
    AppBaseComponent,
    BannerComponent,
  ],
  exports: [
    AppPagebaseComponent,
    AppBaseComponent,
    TranslateModule,
    BannerComponent,

  ],
  imports: [
    PrimengModule,
    TranslateModule,
  ],
  providers: [
  ]
})
export class CoreModule { }
