import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-pagebase',
  templateUrl: './app.pagebase.component.html',
  styleUrls: ['./app.pagebase.component.scss'],
})
export class AppPagebaseComponent {

  //------------------------------------------------------------------------------------------------
  //Variables
  //------------------------------------------------------------------------------------------------

  @Input() progress: boolean;
  @Input() styleClass: string = '';
  @Input() fillWidth: boolean = false;

  //------------------------------------------------------------------------------------------------
  //Constructor
  //------------------------------------------------------------------------------------------------

  constructor(
  ) {
  }

  //------------------------------------------------------------------------------------------------
  //Methods
  //------------------------------------------------------------------------------------------------

  ngOnInit() {

  }
}
