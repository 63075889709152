import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { ActivatedRoute } from '@angular/router';
import { MessageKeys } from 'src/app/constants/messageKeys';
import { ProgressMessageService } from 'src/app/services/progress.message.service';
import { TranslateService } from '@ngx-translate/core';
import { HttpStatusCode } from '@angular/common/http';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, AfterViewInit {
  messageKeys = MessageKeys;
  form: FormGroup;
  forgottenPasswordForm: FormGroup;

  constructor(
    public authService: AuthService,
    private messageService: ProgressMessageService,
    private translateService: TranslateService,
    private route: ActivatedRoute) { }

    get forgottenPasswordFormEmail() { return this.forgottenPasswordForm.get('email'); }

  ngOnInit(): void {
    this.form = new FormGroup({
      email: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required]),
    });

    this.forgottenPasswordForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
  });

    this.route.queryParams.subscribe((params) => {
      if (params['id'] && params['hash']) {
        this.authService.verifyEmail(params['id'], params['hash']);
      }
    });
  }

  ngAfterViewInit() {
    if (this.route.snapshot.queryParams['registered'] == '') {
      this.messageService.addSuccess(
        MessageKeys.Login,
        this.translateService.instant('signup.signupSuccess')
      );
    }
  }

  login() {
    this.authService.login(this.form.value.email, this.form.value.password);
  }

  sendPasswordResetEmail() {
    this.authService.resetPasswordViaEmail()
        .then(() => {
            this.toggleEmailForPasswordResetStartDialog();
            this.toggleEmailForPasswordResetEndDialog();
        })
        .catch((error) => {
            if (error.status == HttpStatusCode.NotFound) {
                this.messageService.addError(MessageKeys.ResetPass, 'Nincs ilyen e-mail cím!');
                return;
            }
            if (error.status == HttpStatusCode.Forbidden) {
                this.messageService.addError(MessageKeys.ResetPass, 'Az e-mail cím nem ellenőrzött!');
                return;
            }
            this.messageService.addError(MessageKeys.ResetPass, 'Hiba történt a háttérben!');
        });
}


  toggleEmailForPasswordResetStartDialog() {
    this.authService.emailForPasswordResetStartDialog = !this.authService.emailForPasswordResetStartDialog;
  }

  toggleEmailForPasswordResetEndDialog() {
    this.authService.emailForPasswordResetEndDialog = !this.authService.emailForPasswordResetEndDialog;
  }
}
