import { Directive } from '@angular/core';

@Directive({
    selector: 'app-base',
})
export class AppBaseComponent {

  //------------------------------------------------------------------------------------------------
  //Variables
  //------------------------------------------------------------------------------------------------

  progress: boolean;

  //------------------------------------------------------------------------------------------------
  //Constructor
  //------------------------------------------------------------------------------------------------

  constructor(
  ) {
  }

  //------------------------------------------------------------------------------------------------
  //Methods
  //------------------------------------------------------------------------------------------------

  progressOn() {
    this.progress = true;
  }

  progressOff() {
    this.progress = false;
  }
}
