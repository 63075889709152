import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { ReservationService } from 'src/app/services/reservation.service';
import { environment } from '../../../environments/environment';
import { CountdownComponent } from 'ngx-countdown';
import { SystemService } from 'src/app/services/system.service';
import { lastValueFrom } from 'rxjs';

interface IEquipment {
  id: string;
  name: string;
}

interface IReservationItem {
  id: string;
  title: string;
  room_name: string;
  price: string;
  difference: string;
  modified_price: string;
}

interface IReservationEquipment {
  reservation: IReservationItem;
  equipment: IEquipment;
}

@Component({
  selector: 'app-reservation-page',
  templateUrl: './reservation-page.component.html',
  styleUrls: ['./reservation-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReservationPageComponent implements OnInit, AfterViewInit {
  @ViewChild('cd', { static: false }) private countdown: CountdownComponent;
  timerConfig: any;
  isAfterViewInitChecked: boolean = false;

  paymentMethods: { id: string; name: string; handle: string }[] = [];
  equipmentData: IEquipment;
  userName: string;
  userEmail: string;
  selectedPaymentMethod: { id: string; name: string; handle: string };

  siteUrl = environment.selfUrl;
  locale = localStorage.getItem('locale') ?? 'hu';

  selectedEquipmentData: { id: string; name: string }[] = [];
  selectedReservationEquipments2: string[] = [];
  selectedReservationEquipments: IReservationEquipment[] = [];

  termsAccepted: boolean = false;
  otpTermsAccepted: boolean = false;
  editId: string;

  progress: boolean = false;

  constructor(
    private cdr: ChangeDetectorRef,
    private authService: AuthService,
    public reservationService: ReservationService,
    private router: Router,
    public systemService: SystemService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    let id = this.route.snapshot.params['id'];

    if (id) {
      this.editId = id;

      if (!this.reservationService.reservationData) {
        this.router.navigate(['/reservation/edit/', this.editId]);
        return;
      }
    }

    this.userName = this.authService.userData.name;
    this.userEmail = this.authService.userData.email;

    if (!!this.reservationService.reservationData) {
      Promise.all([
        lastValueFrom(this.reservationService.getPaymentMethods()).then((result) => {
          this.paymentMethods = result?.data;
        }),
        lastValueFrom(this.reservationService.getEquipmentData()).then((result) => {
          this.equipmentData = result.data;
        })
      ]).then(() => {
        this.cdr.detectChanges();
      });
    } else {
      this.router.navigate(['/login']);
    }
  }

  ngAfterViewInit(): void {
    let startTime = new Date(
      this.reservationService.reservationData.created_at
    );
    let now = new Date();
    let difference = (now.getTime() - startTime.getTime()) / 1000;
    this.timerConfig = {
      format: 'mm:ss',
      leftTime: 1200 - difference,
    };
    //this.countdown.begin();
    this.isAfterViewInitChecked = true;
  }

  equipmentUsagePrice: number | null = null;
  equipmentUsageResponse: IReservationItem[] | null = null;

  async onEquipmentDataSelection() {
    if (this.selectedEquipmentData.length == 0) {
      this.selectedReservationEquipments = [];
      this.selectedReservationEquipments2 = [];
      const res = await this.reservationService.setEquipmentUsage(
        this.reservationService.reservationData.id,
        [],
        []
      );
      this.equipmentUsageResponse = res.data.items;
      this.equipmentUsagePrice = res.data.price;
      this.cdr.detectChanges();
    }
  }

  async onEquipmentSelection(reservation, equipment) {
    const elem = { reservation: reservation, equipment: equipment };

    if (this.selectedReservationEquipments2.length == 0) {
      this.selectedReservationEquipments = [];
    }
    if (
      this.selectedReservationEquipments2.includes(
        reservation.id + '_' + equipment.id
      )
    ) {
      this.selectedReservationEquipments.push(elem);
    } else {
      this.selectedReservationEquipments.splice(
        this.selectedReservationEquipments.indexOf(elem),
        1
      );
    }

    const res = await this.reservationService.setEquipmentUsage(
      this.reservationService.reservationData.id,
      this.selectedReservationEquipments.map((re) => re.reservation.id),
      []
    );
    this.equipmentUsageResponse = res.data.items;
    this.equipmentUsagePrice = res.data.price;
    this.cdr.detectChanges();
  }

  /* get equipmentLinesForReservationItems() {
    const lines: IReservationItem[] = [];
    if (this.equipmentUsageResponse == null) return lines;
    for (let reservationItem of this.reservationService.reservationData.items) {
      const equipmentUsageResponseItem = this.equipmentUsageResponse.find(
        (i) => i.id === reservationItem.id
      );
      if (!equipmentUsageResponseItem) continue;
      lines.push({
        ...reservationItem,
        price: `${
          parseInt(equipmentUsageResponseItem.price) -
          parseInt(reservationItem.price)
        }`,
      });
    }
    return lines;
  } */

  // get reservationPrice() {
  //   if (!this.equipmentUsagePrice)
  //     return this.reservationService.reservationData.price;
  //   return this.equipmentUsagePrice;
  // }

  async onSubmit() {
    this.progress = true;
    this.reservationService
      .getReservationPaymentUrl(
        this.selectedPaymentMethod ? this.selectedPaymentMethod.id : undefined
      )
      .subscribe((result) => {
        this.progress = false;
        if (result?.success == true) {
          window.location.href = result.url;
        }
      });
  }

  redirectToCalendar() {
    this.router.navigate(['/calendar']);
  }

  deleteReservationItem(reservationId: string, itemId: string) {
    if (this.reservationService.reservationData.items.length === 1) return;

    this.progress = true;
    this.reservationService
      .deleteReservationItem(reservationId, itemId)
      .subscribe((result) => {
        this.reservationService.getReservationSummary().then(() => {
          this.cdr.detectChanges();
          this.progress = false;
        });
      });
  }

  handleTimerEvent(event) {
    if (this.isAfterViewInitChecked && event.action === 'done') {
      this.redirectToCalendar();
    }
  }
}
