<p-blockUI [blocked]="progress"></p-blockUI>
<div *ngIf="progress" class="w-full">
  <p-progressBar mode="indeterminate"></p-progressBar>
</div>
<div *ngIf="!progress" class="reservation-summary-page">
  <!-- <h2>
    {{ 'reservationsummary.thanks' | translate }}
  </h2> -->
  <div>
    <div class="flex flex-column justify-content-center">
      <span [ngClass]="{'text-red-500': !reservationData.success}" [innerHTML]="reservationData.message"></span>
    </div>
    <ng-container *ngIf="reservationData.success">
      <div>
        {{ 'reservationsummary.id' | translate }} {{ reservationData.reservation_reference }}
      </div>
      <div> {{ 'reservationsummary.data' | translate }}</div>
      <div *ngFor="let item of reservationData.items">
        <span>
          {{ item.room_name }}
        </span>
        <span>
          {{ item.reserved_time }}
        </span>
      </div>
      <div>
        {{ 'reservationsummary.details' | translate }}
      </div>
    </ng-container>
  </div>
</div>