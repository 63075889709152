export const Colors = { 
    black: "#212121",
    red: "#FF1A00",
    red2: "#FF6A5A",
    red3: "#FECDC8",
    beige: "#F7F5E2",
    grey: "#F6F6F7",
    yellow: "#FEDC00",
    orange: "#FFB22E",
    magenta: "#FF2CCB",
    lilac: "#AA28F7",
    turkiz: "#00D7BA",
    blue: "#00B3F6",
    green: "#8CE000",
    yellow2: "#FFF6BA",
    orange2: "#FFDFA7",
    magenta2: "#FFB5ED",
    lilac2: "#DDA9FC",
    turkiz2: "#8CEDE0",
    blue2: "#A6E4FC",
    green2: "#DBF5B0",
}