import { Component, OnInit } from '@angular/core';
import { SeoService } from "../../services/seo.service";
import { Colors } from 'src/app/constants/colors';
import { Router } from '@angular/router';
import { RoomsService } from 'src/app/services/rooms.service';

@Component({
  selector: 'app-prices',
  templateUrl: './prices.component.html',
  styleUrls: ['./prices.component.scss']
})
export class PricesComponent implements OnInit {

  constructor(
    private seoService: SeoService,
    public roomsService: RoomsService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.seoService.updateTitle('Árak');
    this.seoService.updateTags('Groovy árak', ['lorem ipsum']);
    this.roomsService.list().then(() => {
      this.roomsService.rooms[0].buttonColor = Colors.orange2;
      this.roomsService.rooms[0].buttonTextColor = Colors.orange;
      this.roomsService.rooms[1].buttonColor = Colors.green2;
      this.roomsService.rooms[1].buttonTextColor = Colors.green;
      this.roomsService.rooms[2].buttonColor = Colors.red3;
      this.roomsService.rooms[2].buttonTextColor = Colors.red2;
    });
  }

  handleReservationClick() {
    this.router.navigate(['/calendar'])
  }
}
