<p-blockUI [blocked]="progress"></p-blockUI>
<div class="reservation-page-container">
  <div class="grid ml-0 sm:ml-5 reservation-columns-container">
    <div class="col-12">
      <h2>{{'reservation.bookTitle' | translate}}</h2>
      <h4>
        {{'reservation.finaliseBook' | translate}}
        <span class="hidden sm:inline bg-red-500 text-200 p-2">{{'reservation.finaliseBook2' | translate}}</span>
      </h4>
      <div class="block sm:hidden bg-red-500 text-200 line-height-3 p-2">{{'reservation.finaliseBook2' | translate}}
      </div>
    </div>
    <div *ngIf="editId" class="col-12">{{'reservation.modifyBookInfo' | translate}}</div>
    <div *ngIf="progress" class="col-12">
      <p-progressBar mode="indeterminate"></p-progressBar>
    </div>
    <div class="block sm:hidden col-12 sm:col-6 reservation-details">
      <ng-container *ngTemplateOutlet="reservationDetails"></ng-container>
    </div>
    <div class="col-12 sm:sol-6 reservation-interactables">
      <h3>{{'reservation.personalData' | translate}}</h3>
      <span> {{'base.name' | translate}}: {{ userName }} </span>
      <span> {{'base.email' | translate}}: {{ userEmail }} </span>
      <h3>{{'reservation.rentInstrumentTitle' | translate}}</h3>
      <span>
        {{'reservation.rentInstrumentDetails' | translate}}
      </span>
      <div class="reservation-equipment" *ngIf="!!equipmentData">
        <p-checkbox inputId="{{ 'eq' + equipmentData.id }}" styleClass="mr-2" [value]="equipmentData"
          [(ngModel)]="selectedEquipmentData" (onChange)="onEquipmentDataSelection()"></p-checkbox>
        <label for="{{ 'eq' + equipmentData.id }}">
          {{ equipmentData.name }}</label>
      </div>
      <div class="reservation-equipment-details flex flex-column" *ngFor="let equipment of selectedEquipmentData">
        <div class="reservation-equipment-lines-title">
          {{ equipment.name }} {{'reservation.rent' | translate}}
        </div>
        <div *ngFor="let reservation of reservationService.reservationData?.items">
          <div class="flex flex-col reservation-equipment-line">
            <p-checkbox inputId="{{ reservation.id + '_' + equipment.id }}" styleClass="mr-2"
              [value]="reservation.id + '_' + equipment.id" [(ngModel)]="selectedReservationEquipments2"
              (onChange)="onEquipmentSelection(reservation, equipment)"></p-checkbox>
            <label for="{{ reservation.id + '_' + equipment.id }}">
              {{ reservation.room_name }} <br />
              {{ reservation.title }}
            </label>
          </div>
        </div>
      </div>
      <h3>{{'billing.address' | translate}}</h3>
      <span>
        {{'reservation.billingDetails' | translate}}
      </span>
      <span class="red2-text">
        {{'reservation.billingDetails2' | translate}}
      </span>
      <h3 *ngIf="reservationService?.reservationData?.needs_payment">
        {{'reservation.paymentmethod' | translate}}
      </h3>
      <div *ngIf="reservationService?.reservationData?.needs_payment" class="reservation-payment-column">
        <div class="reservation-payment-row flex align-items-center" *ngFor="let method of paymentMethods">
          <p-radioButton name="paymentMethod" [value]="method" [(ngModel)]="selectedPaymentMethod"
            [inputId]="method.name"></p-radioButton>
          <label [for]="method.name" class="ml-2"> {{ method.name }}</label>
          <ng-container *ngIf="
              method.handle === 'simple-pay';
              then simpleLogo;
              else paypalLogo
            ">
          </ng-container>
          <ng-template #simpleLogo>
            <a href="http://simplepartner.hu/PaymentService/Fizetesi_tajekoztato.pdf" target="_blank" class="ml-auto">
              <img width="197" src="/assets/images/simple_logo.png" title=" SimplePay - Online bankkártyás fizetés"
                alt=" SimplePay vásárlói tájékoztató" />
            </a>
          </ng-template>
          <ng-template #paypalLogo>
            <a class="ml-auto mr-6" href="https://www.paypal.com/c2/webapps/mpp/home?locale.x=en_C2" title="PayPal"
              onclick="javascript:window.open('https://www.paypal.com/c2/webapps/mpp/home?locale.x=en_C2','WIPaypal','toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=1060, height=700'); return false;"><img
                src="https://www.paypalobjects.com/webstatic/mktg/Logo/pp-logo-100px.png" border="0"
                alt="PayPal Logo" /></a>
          </ng-template>
        </div>
      </div>
      <div class="mb-4"></div>
      <div class="flex flex-row align-items-center">
        <p-checkbox [binary]="true" inputId="aszf" styleClass="mb-2 mt-2 mr-2" [(ngModel)]="termsAccepted"></p-checkbox>
        <label for="aszf">
          {{ 'signup.acceptTermsVerb' | translate }} <a class="terms" [href]="systemService.settings.legal.terms"
            target="_blank">{{ 'signup.termsOfService' | translate }}</a>
        </label>
      </div>
      <div *ngIf="
          selectedPaymentMethod && selectedPaymentMethod.handle == 'simple-pay'
        " class="flex flex-row align-items-baseline mt-3">
        <p-checkbox [binary]="true" inputId="otp_terms" styleClass="mb-2 mt-2 mr-2"
          [(ngModel)]="otpTermsAccepted"></p-checkbox>
        <label for="otp_terms">
          <span *ngIf="locale === 'hu'; else englishOtp">
            Tudomásul veszem, hogy a(z)
            <strong>
              Reeds Production Korlátolt Felelősségű Társaság ( 1121 Budapest,
              Rácz Aladár utca 136.)
            </strong>
            adatkezelő által a(z) <strong>{{ siteUrl }}</strong> felhasználói
            adatbázisában tárolt alábbi személyes adataim átadásra kerülnek az
            OTP Mobil Kft., mint adatfeldolgozó részére. Az adatkezelő által
            továbbított adatok köre az alábbi:
            <ul>
              <li>E-mail cím</li>
              <li>Név</li>
            </ul>

            Az adatfeldolgozó által végzett adatfeldolgozási tevékenység jellege
            és célja a SimplePay Adatkezelési tájékoztatóban, az alábbi linken
            tekinthető meg:
            <a href="http://simplepay.hu/vasarlo-aff" target="_blank"
              style="color: black">http://simplepay.hu/vasarlo-aff
            </a>
          </span>
          <ng-template #englishOtp>
            <span>
              I acknowledge the following personal data stored in the user
              account of
              <strong>
                Reeds Production Korlátolt Felelősségű Társaság (1121 Budapest,
                Rácz Aladár utca 136.)
              </strong>
              in the user database of <strong>{{ siteUrl }}</strong> will be
              handed over to OTP Mobil Ltd. and is trusted as data processor.
              The data transferred by the data controller are the following:
              <ul>
                <li>E-mail address</li>
                <li>Name</li>
              </ul>
              The nature and purpose of the data processing activity performed
              by the data processor in the SimplePay Privacy Policy can be found
              at the following link:
              <a href="http://simplepay.hu/vasarlo-aff" target="_blank"
                style="color: black">http://simplepay.hu/vasarlo-aff
              </a>
            </span>
          </ng-template>
        </label>
      </div>
      <div class="mb-4"></div>
      <div class="flex flex-column sm:flex-row row-gap-2 justify-content-between align-items-center">
        <button pButton class="button-red2" (click)="redirectToCalendar()"
          [label]="'reservation.goToCalendarBtn' | translate"></button>
        <button *ngIf="reservationService.reservationData?.needs_payment" pButton [disabled]="
            !selectedPaymentMethod ||
            !termsAccepted ||
            (selectedPaymentMethod &&
              selectedPaymentMethod.handle == 'simple-pay' &&
              !otpTermsAccepted)
          " class="button-red2" [label]="'reservation.payAndBookBtn' | translate" (click)="onSubmit()"></button>
        <button *ngIf="!reservationService.reservationData?.needs_payment" pButton [disabled]="!termsAccepted"
          class="button-red2" [label]="'reservation.bookBtn' | translate" (click)="onSubmit()"></button>
      </div>
      <div *ngIf="progress">
        <p-progressBar mode="indeterminate"></p-progressBar>
      </div>
      <div class="border-round bg-red-300 w-full sm:w-6 mt-2 p-3 font-bold grid">
        <div class="col-1">
          <i class="pi pi-clock" style="font-size: 2rem"></i>
        </div>
        <div class="col-10 ml-2">
          {{ 'reservation.hurryBooking' | translate }}<br />
          {{ 'reservation.hurryBooking2' | translate }}
        </div>
        <div class="col-1"></div>
        <div class="col-10 ml-2 text-2xl">
          <countdown *ngIf="timerConfig" #cd [config]="timerConfig" (event)="handleTimerEvent($event)"></countdown>
        </div>
      </div>
    </div>
    <div class="hidden sm:block col-12 sm:col-6 reservation-details">
      <ng-container *ngTemplateOutlet="reservationDetails"></ng-container>
    </div>
  </div>
</div>
<ng-template #reservationDetails>
  <h3>{{'reservation.bookedTimes' | translate}}</h3>
  <div class="reservation-items-column">
    <div *ngFor="let reservation of reservationService.reservationData?.items">
      <div class="reservation-item">
        <div class="flex flex-row justify-content-between">
          <span>
            {{ reservation.room_name }}
          </span>
          <span class="reservation-item-price">
            {{
            reservation.difference
            ? reservation.difference +
            " Ft (" + ('reservation.originalPrice' | translate) + ": " +
            reservation.price +
            " Ft)"
            : reservation.price + " Ft"
            }}
          </span>
        </div>
        <div class="flex flex-row">
          {{ reservation.title }}
        </div>
        <div class="flex flex-row justify-content-end">
          <div class="reservation-bin-container">
            <span *ngIf="reservationService.reservationData?.items.length > 1" (click)="
                deleteReservationItem(
                  reservationService.reservationData?.id,
                  reservation.id
                )
              ">
              <img src="assets/images/bin.svg" />
            </span>
          </div>
        </div>
      </div>
    </div>
    <!-- <div *ngFor="let reservation of equipmentLinesForReservationItems">
      <div class="reservation-item">
        <div class="flex flex-row">Zongora bérlése</div>
        <div class="flex flex-row justify-content-between">
          <span>
            {{ reservation.room_name }}
          </span>
          <span class="reservation-item-price">
            {{ reservation.price }} Ft
          </span>
        </div>
        <div class="flex flex-row">
          {{ reservation.title }}
        </div>
        <div class="flex flex-row justify-content-end">
          <div class="reservation-bin-container">
            <img src="assets/images/bin.svg" />
          </div>
        </div>
      </div>
    </div> -->
  </div>
  <!-- <div class="reservation-details-modify-row red2-text">
    Módosítanál a foglalt időpontokon? Kattints ide és lépj vissza a naptárhoz!
  </div> -->
  <div class="reservation-details-summary-row">
    <span>{{'reservation.sum' | translate}}</span>
    <span class="reservation-details-summary-sum">
      <span *ngIf="
          reservationService?.reservationData?.needs_payment;
          else zeroDifference
        ">
        {{
        reservationService.reservationData?.difference
        ? reservationService.reservationData?.difference +
        " Ft (" + ('reservation.originalPrice' | translate) + ": " +
        reservationService.reservationData?.price +
        " Ft)"
        : reservationService.reservationData?.price + " Ft"
        }}
      </span>
      <ng-template #zeroDifference><span> 0 Ft </span></ng-template>
    </span>
  </div>
</ng-template>