import { Component, OnInit } from '@angular/core';
import { SystemService } from 'src/app/services/system.service';

@Component({
  selector: 'app-impressum',
  templateUrl: './impressum.component.html',
  styleUrls: ['./impressum.component.scss'],
})
export class ImpressumComponent implements OnInit {
  constructor(public systemService: SystemService) {}
  settings: any;
  ngOnInit(): void {
    this.settings = this.systemService.settings.impressum;
  }
}
