import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class NewsletterService {

  constructor(
    private http: HttpClient
  ) { }

  subscribeToNewsletter(name: string, email: string) {
    const url: string = environment.apiUrl + 'api/subscribe';
    return this.http.post(url, { name: name, email: email });
  }
}
