<div class="">
  <!-- <div class="hidden sm:block p-0 bg-gray-100">
    <app-carousel [items]="banners"></app-carousel>
  </div>
  <div class="block sm:hidden p-0 bg-gray-100">
    <app-carousel [showNavigators]="false" [items]="banners"></app-carousel>
  </div> -->
  <div class="info-container">
    <div
      class="flex flex-column sm:flex-row row-gap-3 sm:row-gap-0 sm:column-gap-8 justify-content-center info-contents my-8">
      <div class="info-item-container w-9 mx-auto sm:mx-0 sm:w-2" *ngFor="let item of cards">
        <div [style.background-color]="item.color" class="info-item-icon-box">
          <img [src]="item.icon" />
        </div>
        <div class="info-item-title">
          {{ item.title }}
        </div>
        <div class="info-item-description px-2">
          {{ item.description }}
        </div>
      </div>
    </div>
  </div>
  <div class="grid offers-container bg-white mt-3 mb-3">
    <div class="col-12 sm:col-offset-2 sm:col-3 flex flex-column justify-content-center offers-left p-5">
      <h2>
        {{'home.provideTitleStart' | translate}}
        <br />
        <span>{{'home.provideTitleGroovy' | translate}}</span>{{'home.provideTitleEnd' | translate}}
      </h2>
      <div>
        {{'home.provideDetails' | translate}}
      </div>
    </div>
    <div class="col-12 sm:col-offset-1 sm:col-4 p-3 offers-right justify-content-center">
      <div class="offer-right-item-container" *ngFor="let offer of offers">
        <div class="offer-image-container">
          <img [src]="offer.image" />
        </div>
        <span class="offer-image-title"> {{ offer.title }} </span>
      </div>
    </div>
  </div>
  <app-banner backgroundImage="'/assets/images/dob.png'" [title]="'home.bannerTitle' | translate"
    [subtitle]="'home.bannerSubtitle' | translate">
    <div>
      <button pButton class="button-beige" [label]="'home.bannerBtn' | translate" (click)="goToPolicy()"></button>
    </div>
  </app-banner>
  <div class="prices-contact-container">
    <div class="note-container z-1">
      <img src="assets/images/music.svg">
    </div>
    <h2 class="z-1">
      {{'home.forTeachersAndIndividuals' | translate}}
    </h2>
    <button [style.background-color]="'#FFB5ED'" pButton [label]="'rooms.contact_button' | translate"
      [routerLink]="['/contact']" styleClass="z-1"></button>
    <a [routerLink]="['/rooms']" class="z-1">{{'base.showMeTheRooms' | translate}}</a>
  </div>
  <app-banner backgroundColor="#FECDC8" [title]="'banner.oneRehearsalArea' | translate" bgCubesClass="banner-cubes1"
    [subtitle]="'banner.oneRehearsalArea2' | translate">
    <div>
      <button pButton class="button-beige" [label]="'home.registrate' | translate" (click)="goToRegister()"></button>
    </div>
  </app-banner>
</div>