<div>
  <!--   <div class="hidden sm:block p-0 bg-gray-100">
    <app-carousel [items]="banners"></app-carousel>
  </div>
  <div class="block sm:hidden p-0 bg-gray-100">
    <app-carousel [showNavigators]="false" [items]="banners"></app-carousel>
  </div> -->
  <div
    class="flex flex-column sm:flex-row row-gap-6 sm:row-gap-0 sm:column-gap-8 column-gap-3 justify-content-center my-8 sm:mx-0 rooms-container">
    <div *ngFor="let room of roomsService.rooms" class="w-full sm:w-3">
      <app-carousel [showNavigators]="false" [items]="room.gallery | banneralyze"></app-carousel>
      <h3> {{ room.name }} </h3>
      <h4> {{ room.price }} Ft </h4>
      <button [style.background-color]="room.buttonColor" pButton [label]="'rooms.reserve_button' | translate"
        (click)="handleReservationClick()"></button>
      <div [innerHtml]="room.description">
      </div>
    </div>
  </div>
  <app-banner backgroundColor="#FFDFA7" [title]="'banner.oneRehearsalArea' | translate" bgCubesClass="banner-cubes2"
    [subtitle]="'banner.oneRehearsalArea2' | translate">
    <div>
      <button pButton class="button-beige" [label]="'home.registrate' | translate"
        (click)="handleRegisterClick()"></button>
    </div>
  </app-banner>
</div>