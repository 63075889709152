<div class="hidden sm:block p-0 bg-gray-100">
  <app-carousel [items]="banners"></app-carousel>
</div>
<div class="block sm:hidden p-0 bg-gray-100">
  <app-carousel [showNavigators]="false" [items]="banners"></app-carousel>
</div>
<div class="col-10 col-offset-1 p-3">
  <div class="grid">
      <ng-container *ngFor="let content of contentsAbove">
        <div class="col-12 flex align-items-center">
          <div [innerHtml]="content.content"></div>
        </div>
      </ng-container>
      <ng-container *ngFor="let medium of media">
        <div *ngIf="isVideo(medium.url)" class="col-12 px-6 flex align-items-center">
          <iframe width="1275" height="717" [src]="medium.url" frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen></iframe>
        </div>
        <div *ngIf="!isVideo(medium.url)" class="col-12 px-6 flex align-items-center">
          <img [src]="medium.url" [alt]="medium.name">
        </div>
      </ng-container>
    </div>
</div>
<div class="col-10 col-offset-1">
    <div class="p-3 grid">
        <ng-container *ngFor="let content of contentsBelow">
            <div class="col-12flex align-items-center">
                <div [innerHtml]="content.content"></div>
            </div>
        </ng-container>
    </div>
</div>
