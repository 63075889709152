import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PrimengModule } from './primeng.module';
import { CoreModule } from './core.module';
import { AppFooterComponent } from '../core/footer/app.footer.component';
import { AppTopbarComponent } from '../core/topbar/app.topbar.component';
import { AppMenuComponent } from '../core/menu/app.menu.component';
import { AppMainComponent } from '../core/main/app.main.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import { CalendarComponent } from '../pages/calendar/calendar.component';

@NgModule({
  declarations: [
    AppMainComponent,
    AppMenuComponent,
    AppTopbarComponent,
    AppFooterComponent,
    CalendarComponent,
  ],
  imports: [
    PrimengModule,
    ReactiveFormsModule,
    FormsModule,
    CoreModule,
    FullCalendarModule,
  ],
  exports: [CalendarComponent],
})
export class FeatureModule {}
