<p-blockUI [blocked]="authService.loginProgress"></p-blockUI>
<div class="login-container grid">
  <div class="col-10 sm:col-5 col-offset-1">
    <div class="mb-3">
      <p-message severity="error" [text]="authService.errorMessage" *ngIf="authService.errorMessage"></p-message>
      <p-message [severity]="authService.verifyType" [text]="authService.verifyMessage"
        *ngIf="authService.verifyMessage"></p-message>
    </div>
    <div class="login-welcome">{{'signin.welcomeTitle' | translate}}</div>
    <div class="login-info">
      {{'signin.welcomeDetails' | translate}}
      <a [routerLink]="'/register'" class="login-info text-red-700">{{'base.clickHere' | translate}}</a>
    </div>
    <p-messages [key]="messageKeys.Login"></p-messages>
    <div [formGroup]="form">
      <div class="mb-3">
        <label>{{ "base.email" | translate }}</label>
        <input type="text" id="email" pInputText formControlName="email" class="w-full" />
      </div>
      <div class="float-right">
        <a class="terms" (click)="toggleEmailForPasswordResetStartDialog()">{{'signin.forgotPassword' |
          translate}}</a>
      </div>
      <div class="mb-3">
        <label>{{ "base.password" | translate }}</label>
        <p-password class="w-full" [style]="{ width: '100%' }" [inputStyle]="{ width: '100%' }" id="password"
          formControlName="password" [feedback]="false" [toggleMask]="true" (keyup.enter)="login()"></p-password>
      </div>
      <div *ngIf="authService.loginProgress" class="mb-3">
        <p-progressBar mode="indeterminate"></p-progressBar>
      </div>
      <div>
        <p-button [label]="'base.signin' | translate" (onClick)="login()" styleClass="mb-3 w-full"></p-button>
      </div>
    </div>
  </div>
  <div class="col-12 sm:col-5">
    <img src="/assets/images/dob.png" width="100%" />
  </div>
</div>
<app-banner backgroundColor="#DBF5B0" [title]="'banner.oneRehearsalArea' | translate" bgCubesClass="banner-cubes4"
  [subtitle]="'banner.oneRehearsalArea2' | translate">
  <div>
    <button pButton class="button-beige" [label]="'home.registrate' | translate" [routerLink]="['/register']"></button>
  </div>
</app-banner>

<p-dialog [showHeader]="false" [modal]="true" [blockScroll]="true" [resizable]="false"
  [(visible)]="authService.emailForPasswordResetStartDialog" [breakpoints]="{'768px': '100vw'}"
  [style]="{width: '42vw'}" [formGroup]="forgottenPasswordForm">
  <div class="flex flex-column justify-content-center p-3 row-gap-1 relative overflow-hidden">
    <div class="flex flex-column">
      <div class="flex">
        <div class="flex justify-content-center ml-3 w-full">
          <b>{{'forgotPassword.title' | translate}}</b>
        </div>
        <div class="flex justify-content-end w-1rem">
          <a class="text-900 float-right" (click)="toggleEmailForPasswordResetStartDialog()"><i class="pi pi-times"></i></a>
        </div>
      </div>
      <div class="flex justify-content-center w-full sm:w-10 mx-auto">
        <p>{{'forgotPassword.details' | translate}}</p>
      </div>
      <div class="w-9 mx-auto">
        <p-messages [key]="messageKeys.ResetPass"></p-messages>
      </div>
    </div>
    <div class="flex justify-content-center w-full sm:w-7 mx-auto">
      <input type="text" pInputText formControlName="email" class="form-control"
        [placeholder]="'forgotPassword.placeholder' | translate" [(ngModel)]="authService.emailForPasswordReset" class="w-full"/>
    </div>
    <div *ngIf="forgottenPasswordFormEmail!.errors?.['email']"
      class="text-red-500 justify-content-center flex font-bold">{{ 'base.emailFormatBad' | translate}}</div>
    <div class="flex justify-content-center w-6 mx-auto">
      <p-button [label]="'base.send' | translate" (click)="sendPasswordResetEmail()" styleClass="mt-2"></p-button>
    </div>
  </div>
</p-dialog>
<p-dialog [showHeader]="false" [modal]="true" [blockScroll]="true" [resizable]="false"
  [(visible)]="authService.emailForPasswordResetEndDialog" [breakpoints]="{'768px': '100vw'}" [style]="{width: '42vw'}">
  <div class="flex flex-column justify-content-center p-3 row-gap-1 relative overflow-hidden">
    <div class="flex flex-column p-3 row-gap-1">
      <div class="flex flex-column">
        <div class="flex">
          <div class="flex justify-content-center ml-3 w-full">
            <b>{{'forgotPassword.title' | translate}}</b>
          </div>
          <div class="flex justify-content-end w-1rem">
            <a class="text-900 float-right" (click)="toggleEmailForPasswordResetEndDialog()"><i class="pi pi-times"></i></a>
          </div>
        </div>
        <div class="flex flex-column text-center w-full sm:w-10 mx-auto">
          <p>{{'forgotPassword.emailSentRow1' | translate}}</p>
          <p>{{'forgotPassword.emailSentRow2' | translate}}</p>
        </div>
      </div>
      <div class="flex justify-content-center w-6 mx-auto">
        <p-button label="BEZÁR" (click)="toggleEmailForPasswordResetEndDialog()" styleClass="mt-2"></p-button>
      </div>
    </div>
  </div>
</p-dialog>