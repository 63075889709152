<div class="grid app-footer">
  <div class="hidden sm:block col-10 col-offset-1">
    <ng-container *ngTemplateOutlet="hirlevelItem"> </ng-container>
  </div>
  <p-divider></p-divider>
  <div class="col-10 col-offset-1">
    <div class="hidden sm:flex grid">
      <div class="md:col-5 lg:col-3 xl:col-3">
        <ng-container *ngTemplateOutlet="kapcsolatItem"> </ng-container>
      </div>
      <div class="md:col-5 lg:col-3 xl:col-3">
        <ng-container *ngTemplateOutlet="informacioItem"> </ng-container>
      </div>
      <div class="md:col-5 lg:col-3 xl:col-3">
        <ng-container *ngTemplateOutlet="nalunkProbalnakItem"> </ng-container>
      </div>
      <div class="md:col-5 lg:col-3 xl:col-3">
        <ng-container *ngTemplateOutlet="hangszerparkItem"> </ng-container>
      </div>
    </div>
  </div>
  <div class="block sm:hidden col-10 col-offset-1">
    <div class="col-12">
      <ng-container *ngTemplateOutlet="hirlevelItem"> </ng-container>
    </div>
    <div class="col-12">
      <ng-container *ngTemplateOutlet="kapcsolatItem"> </ng-container>
    </div>
    <div class="col-12">
      <ng-container *ngTemplateOutlet="informacioItem"> </ng-container>
    </div>
    <div class="col-12">
      <ng-container *ngTemplateOutlet="nalunkProbalnakItem"> </ng-container>
    </div>
    <div class="col-12">
      <ng-container *ngTemplateOutlet="hangszerparkItem"> </ng-container>
    </div>
  </div>
</div>
<div class="flex align-items-center justify-content-center signature p-3 mt-3">
  2023 &copy; REEDS PRODUCTION / DEVELOPED AND POWERED BY DEVWING
</div>

<ng-template #hirlevelItem>
  <div class="text-xl font-bold mt-2">{{'footer.subscribeNow' | translate}}</div>
  <div class="hidden sm:block text-lg mt-4">
    {{'footer.subscribeNow2' | translate}}
  </div>
  <form [formGroup]="subscribeForm" (ngSubmit)="subscribeToNewsLetter()">
    <div class="w-full sm:w-6 mt-2">
      <input type="text" formControlName="name" pInputText [placeholder]="'footer.placeholderName' | translate"
        class="w-full sm:w-7" />
      <div *ngIf="submitted && f['name'].errors" class="bg-pink-600 text-white p-1">
        <div *ngIf="f['name'].errors?.['required']">{{'form.nameRequired' | translate}}</div>
      </div>
      <div class="w-6 bg-pink-600 text-white p-1" *ngIf="errors && errors['name']">
        {{ errors["name"] }}
      </div>
      <input type="text" formControlName="email" pInputText [placeholder]="'forgotPassword.placeholder' | translate"
        forgotPassword class="w-full sm:w-7" />
      <div *ngIf="submitted && f['email'].errors" class="bg-pink-600 text-white p-1">
        <div *ngIf="f['email'].errors?.['required']">
          {{'form.emailRequired' | translate}}
        </div>
        <div *ngIf="f['email'].errors?.['email']">{{'form.emailError' | translate}}</div>
      </div>
      <div class="w-6 bg-pink-600 text-white p-1" *ngIf="errors && errors['email']">
        {{ errors["email"] }}
      </div>
      <br />
      <p-button [label]="'base.subscribe' | translate" styleClass="mt-3 w-full sm:w-4 text-sm"
        (onClick)="subscribeToNewsLetter()"></p-button>
    </div>

    <div class="mt-3">
      <p-checkbox [binary]="true" inputId="binary" formControlName="agree" styleClass="mr-2"></p-checkbox>
      <label for="binary">{{'base.subscribeNewsLetter' | translate}}</label>
      <div *ngIf="submitted && f['agree'].errors" class="bg-pink-600 text-white p-1">
        <div *ngIf="f['agree'].errors?.['required']">{{'form.acceptRequired' | translate}}</div>
      </div>
      <div class="w-6 bg-pink-600 text-white p-1" *ngIf="errors && errors['agree']">
        {{ errors["agree"] }}
      </div>
    </div>
    <div *ngIf="success" class="bg-green-600 text-white p-1 m-1">
      {{'footer.subscribeSuccess' | translate}}
    </div>
  </form>
</ng-template>
<ng-template #informacioItem>
  <h4>{{'footer.information' | translate}}</h4>
  <p><a routerLink="/policy">{{'footer.roompolicy' | translate}}</a></p>
  <p>
    <a [href]="systemService.settings.legal.terms" target="_blank">{{'footer.termsAndPolicy' | translate}}</a>
  </p>
  <p>
    <a routerLink="/legalnotice">{{'legalnotice.title' | translate}}</a>
  </p>
  <p>
    <a routerLink="/faq"> {{'footer.faq' | translate}} </a>
  </p>
</ng-template>
<ng-template #nalunkProbalnakItem>
  <h4>{{'footer.theyRehearseHere' | translate}}</h4>
  <p *ngFor="let item of systemService.settings.bands">{{ item }}</p>
</ng-template>
<ng-template #hangszerparkItem>
  <h4>{{'footer.instruments' | translate}}</h4>
  <p *ngFor="let item of systemService.settings.instruments">
    {{ item }}
  </p>
</ng-template>
<ng-template #kapcsolatItem>
  <div class="flex flex-column justify-content-center sm:justify-content-start">
    <div class="w-6">
      <img src="assets/images/groovy_studio_logo_footer.svg" />
    </div>
    <div class="flex justify-content-center sm:justify-content-start mb-2">
      <a href="https://www.facebook.com/groovystudio.hu" target="_blank"><img src="assets/images/icons/fb_icon.svg"
          width="30px" class="mr-3" /></a>
      <a href="https://www.instagram.com/groovy_studio/" target="_blank"><img src="assets/images/icons/insta_icon.svg"
          width="30px" class="mr-3" /></a>
      <a href="https://www.tiktok.com/@groovy_studio" target="_blank"><img src="assets/images/icons/tiktok_icon.svg"
          width="30px" class="mr-3" /></a>
    </div>
    <!-- <div>1056 Budapest, Régi Posta u. 11.</div>
    <div>{{'days.monday' | translate}} - {{'days.sunday' | translate}}: 00:00 - 24:00</div>
    <a href="mailto:hello@groovystudio.hu">hello@groovystudio.hu</a> -->
  </div>
</ng-template>