<p-carousel
  [value]="items"
  [numVisible]="1"
  [numScroll]="1"
  [circular]="true"
  [showNavigators]="showNavigators"
  [showIndicators]="true"
  [autoplayInterval]="interval"
>
  <ng-template let-item pTemplate="item">
    <div class="border-0 surface-border border-round m-0 text-center py-0 px-0">
      <div class="carousel-container">
        <img
          src="{{ item.image }}"
          [alt]="item.title"
          class="home-carousel-image shadow-2"
        />
        <div *ngIf="item.title">
          <h3 [style.color]="item.titleColor">{{ item.title }}</h3>
          <span [style.color]="item.subTitleColor">{{ item.subTitle }}</span>
        </div>
      </div>
    </div>
  </ng-template>
</p-carousel>
