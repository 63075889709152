<div class="prices-container">
  <div class="prices-main">
    <h2> Áraink </h2>
    <h3>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut nisi orci, viverra sagittis lacus eu, suscipit
      consequat nunc.
    </h3>
    <div
      class="flex flex-column sm:flex-row row-gap-6 sm:row-gap-0 sm:column-gap-8 justify-content-center my-8 mx-5 sm:mx-0 prices-box-container">
      <div *ngFor="let room of roomsService.rooms">
        <div>
          <img src="assets/images/music.svg">
        </div>
        <h4> {{room.name}} </h4>
        <h5> {{room.price}} Ft </h5>
        <button [style.background-color]="room.buttonColor" pButton [label]="'rooms.reserve_button' | translate"
          (click)="handleReservationClick()"></button>
        <a class="mt-3" [style.color]="room.buttonTextColor" [routerLink]="['/rooms']">
          {{'base.showMeTheRooms' | translate}}
        </a>
      </div>
    </div>
  </div>
</div>
<div class="prices-contact-container">
  <img class="prices-cubes" src="assets/images/cubes_prices.svg">
  <div class="note-container">
    <img src="assets/images/music.svg">
  </div>
  <h2> Magántanárok és egyéni gyakorlók keressenek minket bizalommal egyedi ajánlatokért! :) </h2>
  <button [style.background-color]="'#FFB5ED'" pButton [label]="'rooms.contact_button' | translate"
    (click)="null"></button>
  <a>{{'base.showMeTheRooms' | translate}}</a>
</div>