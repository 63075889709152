import { Component, OnInit } from '@angular/core';
import { SeoService } from 'src/app/services/seo.service';
import { SystemService } from 'src/app/services/system.service';

@Component({
  selector: 'app-studiorecording',
  templateUrl: './studiorecording.component.html',
})
export class StudiorecordingComponent implements OnInit {

  contentsAbove: any[] = [];
  contentsBelow: any[] = [];
  media: any[] = [];
  mediaIsAboveBanner: boolean = true;

  constructor(
    private seoService: SeoService,
    public systemService: SystemService,
  ) { }

  ngOnInit(): void {
    this.seoService.updateTitle('Stúdiófelvétel');
    this.seoService.updateTags('Groovy Stúdiófelvétel', ['lorem ipsum']);

    const contents = this.systemService.contents.filter(c => c.slug == 'studiorecording')[0];
    if (contents) {
      contents.blocks.forEach(cb => {
        if (cb.content_above_banner) {
          this.contentsAbove.push(cb);
        } else {
          this.contentsBelow.push(cb);
        }
      });

      this.media = contents.media;
      this.mediaIsAboveBanner = contents.media_above_banner;
    }
  }

  isVideo(item: string) {
    return item.includes('youtube');
  }
}
