import { Component, ElementRef } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  title = 'groovy.angular';

  constructor(private _elementRef: ElementRef) {
  }

  ngOnInit(): void {
      this._elementRef.nativeElement.removeAttribute("ng-version");
  }
}
