import { Component, OnInit, AfterViewInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { MessageKeys } from 'src/app/constants/messageKeys';
import { MessageSeverity } from 'src/app/constants/messageSeverity';
import { AuthService } from 'src/app/services/auth.service';
import { MenuService } from 'src/app/services/menu.service';
import { SystemService } from 'src/app/services/system.service';
import { AppBaseComponent } from '../pagebase/app.base.component';
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html',
    styleUrls: ['./app.topbar.component.scss']
})
export class AppTopbarComponent extends AppBaseComponent implements OnInit, AfterViewInit {

    //------------------------------------------------------------------------------------------------
    //Variables
    //------------------------------------------------------------------------------------------------

    messageKeys = MessageKeys;

    //------------------------------------------------------------------------------------------------
    //Constructor
    //------------------------------------------------------------------------------------------------

    constructor(public authService: AuthService,
        public menuService: MenuService,
        private systemService: SystemService,
        private messageService: MessageService,
                public translate: TranslateService
    ) {
        super();
    }

    //------------------------------------------------------------------------------------------------
    //Methods
    //------------------------------------------------------------------------------------------------

    ngOnInit() {
    }

    ngAfterViewInit() {
        if (this.systemService.isTestEnvToldByAPI) {
            this.messageService.add({ key: MessageKeys.Global, severity: MessageSeverity.Error, summary: 'Ön jelenleg egy tesztkörnyezetet használ, amely fejlesztés alatt áll. Az itt megjelenő adatok csupán tesztadatok, valamint a jelenleg működő funkciók még tesztelési fázisban vannak.' });
        }
    }

  translateLanguageTo(lang: any,click = 0) {
    this.translate.use(lang);
    localStorage.setItem('locale',lang);
    if( click ){
      window.location.assign('/');
    }
  }
}
