import { Pipe, PipeTransform } from "@angular/core";
import { BannerItem } from "../models/bannerItem.model";

@Pipe({name: 'banneralyze'})
export class BanneralyzePipe implements PipeTransform {
    constructor() {
    }
    transform(value: any[]) {
        return value.map<BannerItem>(v => ({   
            id: v.id,
            code: '',
            title: '',
            subTitle: '',
            image: v.url }));
    }
}