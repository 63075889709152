import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-notfound',
  templateUrl: './app.notfound.component.html',
})
export class AppNotfoundComponent implements OnInit, OnDestroy {

  constructor(private router: Router) {
  }

  ngOnInit() {
  }

  ngOnDestroy() {
  }

  goToShop() {
    this.router.navigate(['/termekeink']);
  }

  goToBlog() {
    this.router.navigate(['/blog']);
  }

  goToKnowledgebase() {
    this.router.navigate(['/tudastar']);
  }
}
