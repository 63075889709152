import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiRoute } from '../models/api-route.model';
import { Country } from '../models/country.model';
import { StreetType } from '../models/street-type.model';

@Injectable({
  providedIn: 'root',
})
export class SystemService {
  //------------------------------------------------------------------------------------------------
  //Variables
  //------------------------------------------------------------------------------------------------

  isTestEnvToldByAPI: boolean = false;

  routes: ApiRoute = {};

  countries: Country[];
  streettypes: StreetType[];
  settings: any;
  banners: any;
  contents: any[] = [];

  hungary: Country;

  //------------------------------------------------------------------------------------------------
  //Constructor
  //------------------------------------------------------------------------------------------------

  constructor(private http: HttpClient) {}

  //------------------------------------------------------------------------------------------------
  //Methods
  //------------------------------------------------------------------------------------------------

  async init(): Promise<any> {
    return await Promise.all([
      //this.getBaseInfo(),
      this.getCountries(),
      this.getStreettypes(),
      this.getSiteSettings(),
      this.getBanners(),
      this.getContents()
    ]);
  }

  getBaseInfo(): Promise<any> {
    return lastValueFrom(
      this.http.get(`${environment.apiUrl}${this.routes['api.root'].uri}`)
    ).then((result: any) => {
      this.isTestEnvToldByAPI = result.test_mode ?? false;
    });
  }

  initApiRoutes(): Promise<any> {
    return lastValueFrom(
      this.http.get(
        `${environment.apiUrl}${environment.endpoints.getApiRoutes}`
      )
    );
  }

  getCountries() {
    return lastValueFrom(
      this.http.get(`${environment.apiUrl}${this.routes['api.countries'].uri}`)
    ).then((result: any) => {
      this.countries = result;
      let index = this.countries.findIndex( (x) => x.iso2 == 'HU')
      this.hungary = this.countries.splice(index, 1)[0];
      this.countries.unshift(this.hungary);
    });
  }

  getStreettypes() {
    return lastValueFrom(
      this.http.get(
        `${environment.apiUrl}${this.routes['api.street_types'].uri}`
      )
    ).then((result: any) => {
      this.streettypes = result.data;
    });
  }

  getSiteSettings() {
    return lastValueFrom(
      this.http.get(`${environment.apiUrl}${this.routes['api.get'].uri}`)
    ).then((result: any) => {
      this.settings = result;
    });
  }

  getBanners() {
    return lastValueFrom(
      this.http.get(`${environment.apiUrl}${this.routes['api.banners'].uri}`)
    ).then((result: any) => {
      this.banners = result;
    });
  }

  getContents() {
    return lastValueFrom(
      this.http.get(`${environment.apiUrl}${this.routes['api.content_pages'].uri}`)
    ).then((result: any) => {
      this.contents = result.data;
    });
  }
}
