<ng-container *ngIf="!responsive">
  <div class="grid">
    <div class="col-3 p-0 flex flex-row">
      <a [routerLink]="['/home']">
        <img
          [src]="systemService.settings.logo.url"
          width="75px"
          class="mr-1"
        />
      </a>
      <h4>{{ systemService.settings.logo.slogan }}</h4>
    </div>
    <div class="col p-0 mr-1 flex align-items-center justify-content-end">
      <p-menubar [model]="menuService.model" [autoDisplay]="true" styleClass="">
      </p-menubar>
      <a href="https://www.facebook.com/groovystudio.hu" target="_blank"
        ><img
          src="assets/images/icons/fb_icon.svg"
          width="30px"
          class="mr-2"
          alt=""
      /></a>
      <a href="https://www.instagram.com/groovy_studio/" target="_blank"
        ><img
          src="assets/images/icons/insta_icon.svg"
          width="30px"
          class="mr-2"
          alt=""
      /></a>
      <a href="https://www.tiktok.com/@groovy_studio" target="_blank"
        ><img src="assets/images/icons/tiktok_icon.svg" width="30px" alt=""
      /></a>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="responsive">
  <div class="grid">
    <div class="col-7 p-0 flex flex-row">
      <a [routerLink]="['/home']"
        ><img
          src="assets/images/groovy_studio_logo.svg"
          width="75px"
          class="mr-1"
      /></a>
      <h4>{{ "base.slogan" | translate }}</h4>
    </div>
    <div class="col p-1 m-1 flex align-items-center justify-content-end">
      <a href="https://www.facebook.com/groovystudio.hu" target="_blank"
        ><img src="assets/images/icons/fb_icon.svg" width="30px" class="mr-2"
      /></a>
      <a href="https://www.instagram.com/groovy_studio/" target="_blank"
        ><img
          src="assets/images/icons/insta_icon.svg"
          width="30px"
          class="mr-2"
      /></a>
      <a href="https://www.tiktok.com/@groovy_studio" target="_blank"
        ><img src="assets/images/icons/tiktok_icon.svg" width="30px"
      /></a>
    </div>
    <div class="col-12 flex justify-content-center align-items-center pb-3 p-0">
      <a (click)="menuService.toggleMenu()"
        ><i class="pi pi-bars text-2xl text-900"></i
      ></a>
    </div>
  </div>
</ng-container>
<p-panelMenu
  *ngIf="responsive && menuService.menuToggled"
  [model]="menuService.model"
  styleClass="w-full"
></p-panelMenu>
