import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { lastValueFrom } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'profile-page',
  templateUrl: './profile-page.component.html',
  styleUrls: ['./profile-page.component.scss'],
})
export class ProfilePageComponent implements OnInit {
  constructor(
    public translate: TranslateService,
    public authService: AuthService,
    public router: Router
  ) {}

  async ngOnInit(): Promise<void> {
    if (!this.authService.userData) this.router.navigate(['/login']);
  }

  async goToReservationPage() {
    this.router.navigate(['/calendar']);
  }
  
  logout() {
    this.authService.logout();
  }
}
