import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ReservationService } from 'src/app/services/reservation.service';

interface IReservationPaymentResponse {
  equipment_items: { reserved_time: string, room_name: string }[];
  items: { reserved_time: string, room_name: string }[];
  errors: [];
  message: string;
  reservation_reference: string;
  success: boolean;
  url: string;
}

@Component({
  selector: 'app-reservation-summary-page',
  templateUrl: './reservation-summary-page.component.html',
  styleUrls: ['./reservation-summary-page.component.scss']
})
export class ReservationSummaryPageComponent implements OnInit {

  reservationData: IReservationPaymentResponse;
  progress: boolean = true;

  constructor(
    private reservationService: ReservationService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  async ngOnInit(): Promise<void> {
    const params = this.route.snapshot.queryParams;

    this.reservationService.checkPayment(params).subscribe({
      next: (result: any) => {
        //if (result?.success === true) {
        this.reservationData = result;
        this.progress = false;
        //} else {
        //this.router.navigate(['home'])
        //}
      },
      error: err => {
        this.progress = false;
        //this.router.navigate(['home'])
      }
    });
  }
}
