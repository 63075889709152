import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from '../core/app.component';
import { PrimengModule } from './primeng.module';
import { FeatureModule } from './feature.module';
import { CoreModule } from './core.module';
import { APP_BASE_HREF, DatePipe, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthService } from '../services/auth.service';
import { OAuthModule } from 'angular-oauth2-oidc';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { SystemService } from '../services/system.service';
import { AuthHttpInterceptor } from '../interceptors/auth.interceptor';
import { AuthGuard } from '../guards/auth.guard';
import { MenuService } from '../services/menu.service';
import { AppNotfoundComponent } from '../pages/404/app.notfound.component';
import { LoginComponent } from '../pages/auth/login.component';
import { RegisterComponent } from '../pages/auth/register.component';
import { firstValueFrom } from 'rxjs';
import { RoomsComponent } from '../pages/rooms/rooms.component';
import { PricesComponent } from '../pages/prices/prices.component';
import { AboutUsComponent } from '../pages/about-us/about-us.component';
import { PolicyComponent } from '../pages/policy/policy.component';
import { ContactComponent } from '../pages/contact/contact.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { LocaleInterceptor } from '../interceptors/locale.interceptor';
import { BannerComponent } from '../blocks/banner/banner.component';
import { TagModule } from 'primeng/tag';
import { HomeComponent } from '../pages/home/home.component';
import { FileUploadModule } from 'primeng/fileupload';
import { ReservationPageComponent } from '../pages/reservation-page/reservation-page.component';
import { ReservationSummaryPageComponent } from '../pages/reservation-summary-page/reservation-summary-page.component';
import { ProfilePageComponent } from '../pages/profile/profile-page.component';
import { ProfileCalendarComponent } from '../pages/profile/calendar/profile-calendar.componet';
import { ProfileDataComponent } from '../pages/profile/data/profile-data.component';
import { ProfileReservationsComponent } from '../pages/profile/reservations/profile-reservations-component';
import { ImageModule } from 'primeng/image';
import { ImpressumComponent } from '../pages/impressum/impressum.component';
import { RoomsService } from '../services/rooms.service';
import { BanneralyzePipe } from '../pipes/banneralyze.pipe';
import { ProgressMessageService } from '../services/progress.message.service';
import { CountdownModule } from 'ngx-countdown';
import { FaqComponent } from '../pages/faq/faq.component';
import { ResetPasswordComponent } from '../pages/auth/reset.password.component';
import * as huJson from '../../assets/languages/hu.json';
import * as enJson from '../../assets/languages/en.json';
import { StudiorecordingComponent } from '../pages/studiorecording/studiorecording.component';

export function initializeApp(
  authService: AuthService,
  systemService: SystemService,
  translate: TranslateService
) {
  return async (): Promise<any> => {
    await systemService.initApiRoutes().then((routes: any) => {
      routes.data.forEach((r) => {
        systemService.routes[r.name] = {
          uri: r.uri,
          methods: r.methods,
        };
      });
    });
    await systemService.init();
    await authService.init();
    const defaultLang = 'hu';
    const currentLang = localStorage.getItem('locale');
    translate.setDefaultLang(currentLang ? currentLang : defaultLang);
    translate.setTranslation('hu', huJson);
    translate.setTranslation('en', enJson);
    return firstValueFrom(
      translate.use(currentLang ? currentLang : defaultLang)
    );
  };
}

export function translateHttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/languages/', '.json');
}

@NgModule({
  declarations: [
    BanneralyzePipe,
    AppComponent,
    AppNotfoundComponent,
    LoginComponent,
    RegisterComponent,
    RoomsComponent,
    PricesComponent,
    AboutUsComponent,
    PolicyComponent,
    ContactComponent,
    BannerComponent,
    ReservationPageComponent,
    ReservationSummaryPageComponent,
    HomeComponent,
    ProfilePageComponent,
    ProfileCalendarComponent,
    ProfileReservationsComponent,
    ProfileDataComponent,
    ContactComponent,
    ImpressumComponent,
    FaqComponent,
    ResetPasswordComponent,
    StudiorecordingComponent
  ],
  imports: [
    OAuthModule.forRoot(),
    TranslateModule.forRoot(),
    //{
    // loader: {
    //   provide: TranslateLoader,
    //   useFactory: translateHttpLoaderFactory,
    //   deps: [HttpClient],
    // },
    //}),
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    PrimengModule,
    FeatureModule,
    CoreModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    TagModule,
    FileUploadModule,
    ImageModule,
    CountdownModule,
  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    AuthHttpInterceptor,
    AuthGuard,
    BanneralyzePipe,
    DatePipe,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AuthService, SystemService, TranslateService],
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LocaleInterceptor, multi: true },
    { provide: APP_BASE_HREF, useValue: '/' },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
