<div class="contact-container">
  <div>
    <h2>{{'breadcrumb.contact' | translate}}</h2>
    <h3>{{'contact.policy1' | translate}} <a class="terms" [routerLink]="['/policy']">{{'contact.policy2' |
        translate}}</a> {{'contact.policy3' | translate}}</h3>
    <div class="grid mt-5">
      <div class="col-12 md:col-6 sm: sm:col-12 xl:col-6 lg:col-6">
        <div *ngFor="let contact of contacts" class="mb-4 flex align-content-center">
          <img [src]="contact.iconURL" />
          <span class="pl-3">
            {{ contact.text }}
          </span>
        </div>
      </div>
      <div class="col-12 md:col-6 sm: sm:col-12 xl:col-6 lg:col-6">
        <div *ngFor="let hour of openHours" class="mb-4 flex align-content-center">
          <img [src]="hour.iconURL" />
          <span class="pl-3">
            {{ hour.text }}
          </span>
        </div>
      </div>
      <div class="col-12 grid p-5 mt-5">
        <div class="contact-icons col-12 flex justify-content-center">
          <h2>{{'contact.followUs' | translate}}</h2>
        </div>
        <div class="col-12 flex justify-content-center">
          <a href="https://www.facebook.com/groovystudio.hu" target="_blank"><img src="assets/images/icons/fb_icon.svg"
              width="30px" class="mr-2" alt="" /></a>
          <a href="https://www.instagram.com/groovy_studio/" target="_blank"><img
              src="assets/images/icons/insta_icon.svg" width="30px" class="mr-2" alt="" /></a>
          <a href="https://www.tiktok.com/@groovy_studio" target="_blank"><img src="assets/images/icons/tiktok_icon.svg"
              width="30px" alt="" /></a>
        </div>
      </div>
    </div>
  </div>
</div>
<app-banner backgroundColor="#DBF5B0" [title]="'banner.checkOurRooms' | translate" bgCubesClass="banner-cubes3"
  [subtitle]="'banner.oneRehearsalArea2' | translate">
  <div>
    <button pButton class="button-beige" [label]="'base.rooms' | translate" [routerLink]="['/rooms']"></button>
  </div>
</app-banner>