<app-banner backgroundColor="#FFF6BA" bgCubesClass="banner-cubes4">
  <div>
    <ng-container *ngFor="let content of contentsAbove">
      <div class="col-12 flex align-items-center">
        <div [innerHtml]="content.content"></div>
      </div>
    </ng-container>
    <ng-container *ngFor="let content of contentsBelow">
      <div class="col-12 flex align-items-center">
        <div [innerHtml]="content.content"></div>
      </div>
    </ng-container>
  </div>
</app-banner>
