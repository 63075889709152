import { Component, OnInit } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { MenuService } from 'src/app/services/menu.service';

@Component({
  selector: 'app-main',
  templateUrl: './app.main.component.html'
})
export class AppMainComponent implements OnInit {

  constructor(
    private primengConfig: PrimeNGConfig,
    public menuService: MenuService) { }

  ngOnInit() {
    this.primengConfig.ripple = true;
  }
}
