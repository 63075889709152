import { OAuthService } from 'angular-oauth2-oidc';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { SystemService } from './system.service';
import { Observable, lastValueFrom } from 'rxjs';
import { DatePipe } from '@angular/common';

export interface IMyReservation {
  contract_time_slot: boolean;
  date: string;
  equipment: string;
  id: string;
  reference: string;
  room_name: string;
  time_slot: string;
}

@Injectable({
  providedIn: 'root',
})
export class ReservationService {
  //------------------------------------------------------------------------------------------------
  //Variables
  //------------------------------------------------------------------------------------------------

  reservationData: any = null;
  reservationId: string;
  paymentMethods: string[];

  onHoldEvents: any[];

  //------------------------------------------------------------------------------------------------
  //Constructor
  //------------------------------------------------------------------------------------------------

  constructor(
    private http: HttpClient,
    private oauthService: OAuthService,
    private systemService: SystemService,
    private router: Router,
    private datePipe: DatePipe,
  ) { }

  //------------------------------------------------------------------------------------------------
  //Properties
  //------------------------------------------------------------------------------------------------

  //------------------------------------------------------------------------------------------------
  //Methods
  //------------------------------------------------------------------------------------------------

  async init() { }

  async getUserReservation(): Promise<{ data: IMyReservation[] }> {
    return lastValueFrom(
      this.http.get(environment.apiUrl + 'api/auth/me/reservations')
    ) as any;
  }

  setReservationData(reservationData) {
    // this.reservationData = reservationData;
  }

  async postReservationData(reservationData): Promise<any> {
    return lastValueFrom(
      this.http.post(
        environment.apiUrl + 'api/reservation/set',
        reservationData
      )
    );
  }

  async modifyReservationData(id, reservationData): Promise<any> {
    const start = new Date(reservationData[0].dates[0].start);
    const end = new Date(reservationData[0].dates[0].end);
    const form: any = {};
    form.id = id;
    form.room_id = reservationData[0].room;
    form.date = this.datePipe.transform(start, 'yyyy-MM-dd');
    form.from = this.datePipe.transform(start, 'HH:mm', '+0000');
    form.to = this.datePipe.transform(end, 'HH:mm', '+0000');

    return lastValueFrom(
      this.http.post(
        environment.apiUrl + 'api/auth/me/reservations/modify/' + id,
        form
      )
    ).then((result: any) => {
      this.reservationData = result?.data;
      this.reservationId = result?.data?.id;
    });
  }

  async getReservationSummary(): Promise<any> {
    return lastValueFrom(
      this.http.get(environment.apiUrl + 'api/reservation/summary')
    ).then((result: any) => {
      this.reservationData = result?.data;
      this.reservationId = result?.data?.id;
    });
  }

  getPaymentMethods(): Observable<any> {
    return this.http.get(environment.apiUrl + 'api/payment_methods');
  }

  getEquipmentData(): Observable<any> {
    return this.http.get(environment.apiUrl + 'api/reservation/equipment');
  }

  setEquipmentUsage(
    reservationId: string,
    reservationItemIds: string[],
    equipmentIds: string[]
  ): Promise<any> {
    const payload: any = {
      reservation_id: reservationId,
      reservation_items: reservationItemIds,
    };
    // for (let i = 0; i < reservationItemIds.length; i++) {
    //     payload[`reservation_items.${i}`] = reservationItemIds[i];
    // }
    return lastValueFrom(
      this.http.post(
        environment.apiUrl +
        `api/reservation/${reservationId}/set_equipment_usage`,
        payload
      )
    ).then((result: any) => {
      this.reservationData = result?.data;
      this.reservationId = result?.data?.id;
      return result;
    });
  }

  getReservationPaymentUrl(payment_method?: string): Observable<any> {
    return this.http.post(
      environment.apiUrl + `api/reservation/${this.reservationId}/payment`,
      { payment_method: payment_method }
    );
  }

  checkPayment(params) {
    return this.http.post(`${environment.apiUrl}${this.systemService.routes['api.check_payment'].uri}`, params);
  }

  deleteReservationItem(reservationId: string, itemId: string) {
    return this.http.delete(
      environment.apiUrl + `api/reservation/${reservationId}/item/${itemId}`
    );
  }

  previewReservation(reservationData) {
    return lastValueFrom(
      this.http.post(`${environment.apiUrl}${this.systemService.routes['api.preview_reservation'].uri}`,
        reservationData
      )
    ).then((result: any) => {
      return result;
    });
  }
}
