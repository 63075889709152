import { Component, OnInit } from '@angular/core';
import { BannerItem } from 'src/app/models/bannerItem.model';
import { SystemService } from 'src/app/services/system.service';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
})
export class AboutUsComponent implements OnInit {
  banners: BannerItem[];

  contentsAbove: any[] = [];
  contentsBelow: any[] = [];
  media: any[] = [];
  mediaIsAboveBanner: boolean = true;

  constructor(private systemService: SystemService) { }

  ngOnInit(): void {
    this.banners = this.systemService.banners.about_us ?? [
      {
        id: '1000',
        code: 'f230fh0g3',
        image: '/assets/images/au1.jpg',
      },
      {
        id: '1001',
        code: 'nvklal433',
        image: '/assets/images/au2.jpg'
      },
      {
        id: '1001',
        code: 'nvklal433',
        image: '/assets/images/au3.jpg'
      }
    ];

    const contents = this.systemService.contents.filter(c => c.slug == 'rolunk')[0];
    if (contents) {
      contents.blocks.forEach(cb => {
        if (cb.content_above_banner) {
          this.contentsAbove.push(cb);
        } else {
          this.contentsBelow.push(cb);
        }
      });

      this.media = contents.media;
      this.mediaIsAboveBanner = contents.media_above_banner;
    }
  }

  isVideo(item: string) {
    return item.includes('youtube');
  }
}
