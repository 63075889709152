import { Component, OnInit } from '@angular/core';
import { MessageKeys } from 'src/app/constants/messageKeys';
import { NewsletterService } from '../../services/newsletter.service';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { SystemService } from 'src/app/services/system.service';

@Component({
  selector: 'app-footer',
  templateUrl: './app.footer.component.html',
})
export class AppFooterComponent implements OnInit {
  //------------------------------------------------------------------------------------------------
  //Variables
  //------------------------------------------------------------------------------------------------
  
  subscribeForm: FormGroup = new FormGroup({
    name: new FormControl(''),
    email: new FormControl(''),
    agree: new FormControl(false),
  });
  errors: Object[] = [];
  submitted: boolean = false;
  success: boolean = false;
  messageKeys = MessageKeys;

  //------------------------------------------------------------------------------------------------
  //Constructor
  //------------------------------------------------------------------------------------------------

  constructor(
    public formBuilder: FormBuilder,
    private newsletterService: NewsletterService,
    public systemService: SystemService
  ) {}

  //------------------------------------------------------------------------------------------------
  //Methods
  //------------------------------------------------------------------------------------------------

  ngOnInit(): void {
    this.buildForm();
  }

  buildForm() {
    this.subscribeForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      agree: [false, Validators.requiredTrue],
    });
  }

  get f(): { [key: string]: AbstractControl } {
    return this.subscribeForm.controls;
  }

  subscribeToNewsLetter() {
    this.submitted = true;
    this.success = false;
    if (this.subscribeForm.invalid) {
      return;
    }
    this.newsletterService
      .subscribeToNewsletter(
        this.subscribeForm.value.name,
        this.subscribeForm.value.email
      )
      .subscribe({
        next: (response) => {
          this.success = true;
          this.errors = [];
        },
        error: (err) => {
          this.errors = err.error.errors;
        },
      });
  }
}
