import { Component, OnInit } from '@angular/core';
import { SeoService } from '../../services/seo.service';
import { Colors } from 'src/app/constants/colors';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { BannerItem } from 'src/app/models/bannerItem.model';
import { SystemService } from 'src/app/services/system.service';
import { RoomsService } from 'src/app/services/rooms.service';

@Component({
  selector: 'app-rooms',
  templateUrl: './rooms.component.html',
  styleUrls: ['./rooms.component.scss'],
})
export class RoomsComponent implements OnInit {
  banners: BannerItem[];

  constructor(
    private seoService: SeoService,
    private authService: AuthService,
    private router: Router,
    public systemService: SystemService,
    public roomsService: RoomsService,
  ) { }

  ngOnInit(): void {
    this.seoService.updateTitle('Termek');
    this.seoService.updateTags('Groovy home', ['lorem ipsum']);
    this.banners = this.systemService.banners.rooms_page ?? [
      {
        id: '1000',
        code: 'f230fh0g3',
        title: 'Ők is nálunk próbálnak!',
        subTitle: 'Gyere, próbáld ki te is próbatermeinket!',
        image: '/assets/images/a1.png',
      },
      {
        id: '1001',
        code: 'nvklal433',
        image: '/assets/images/a2.jpg',
      },
      {
        id: '1001',
        code: 'nvklal433',
        image: '/assets/images/a3.jpg',
      },
    ];

    this.roomsService.list().then(() => {
      this.roomsService.rooms[0].buttonColor = Colors.orange2;
      this.roomsService.rooms[0].buttonTextColor = Colors.orange;
      this.roomsService.rooms[1].buttonColor = Colors.green2;
      this.roomsService.rooms[1].buttonTextColor = Colors.green;
      this.roomsService.rooms[2].buttonColor = Colors.red3;
      this.roomsService.rooms[2].buttonTextColor = Colors.red2;
    });
  }

  handleReservationClick() {
    this.router.navigate(['/calendar']);
  }

  handleRegisterClick() {
    if (this.authService.isLoggedIn) {
      this.router.navigate(['/profile']);
    } else {
      this.router.navigate(['/register']);
    }
  }
}
