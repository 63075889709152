import { Component, OnInit } from '@angular/core';
import { AuthService } from "../../services/auth.service";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { SeoService } from "../../services/seo.service";
import { Country } from "../../models/country.model";
import { StreetType } from "../../models/street-type.model";
import { Router } from '@angular/router';
import { ProgressMessageService } from 'src/app/services/progress.message.service';
import { SystemService } from 'src/app/services/system.service';
import { TranslateService } from '@ngx-translate/core';
import { WindowScrollController } from '@fullcalendar/core/internal';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
})
export class RegisterComponent implements OnInit {
  registerForm: FormGroup;
  streetTypes: StreetType[];
  errors: any;
  id_scan: any;
  selectedFiles?: FileList;
  isPostalSame: boolean = true;

  progress: boolean = false;

  uploadedFiles: any[] = [];

  selectedCountry: Country;
  phoneBodyForError: string;

  constructor(
    public systemService: SystemService,
    private authService: AuthService,
    private seoService: SeoService,
    private messageService: ProgressMessageService,
    private translateService: TranslateService,
    private router: Router
  ) { }

  get phone() {
    return this.registerForm.get('phone');
  }

  ngOnInit(): void {
    this.seoService.updateTitle('Register');
    this.seoService.updateTags('Groovy registration', ['lorem ipsum']);
    this.initForm();
  }

  convertJsontoFormData(jsonObject: Object, parentKey?, carryFormData?: FormData): FormData {
    const formData = carryFormData || new FormData();
    let index = 0;

    for (var key in jsonObject) {
      if (jsonObject.hasOwnProperty(key)) {
        if (jsonObject[key] !== null && jsonObject[key] !== undefined) {
          var propName = parentKey || key;
          if (parentKey && this.isObject(jsonObject)) {
            propName = parentKey + '[' + key + ']';
          }

          if (parentKey && this.isArray(jsonObject)) {
            propName = parentKey + '[' + index + ']';
          }

          if (jsonObject[key] instanceof File) {
            formData.append(propName, jsonObject[key]);
          } else if (jsonObject[key] instanceof FileList) {
            for (var j = 0; j < jsonObject[key].length; j++) {
              formData.append(propName + '[' + j + ']', jsonObject[key].item(j));
            }
          } else if (this.isArray(jsonObject[key]) || this.isObject(jsonObject[key])) {
            this.convertJsontoFormData(jsonObject[key], propName, formData);
          } else if (typeof jsonObject[key] === 'boolean') {
            formData.append(propName, +jsonObject[key] ? '1' : '0');
          } else {
            formData.append(propName, jsonObject[key]);
          }
        }
      }
      index++;
    }
    return formData;
  }

  isArray(val) {
    const toString = ({}).toString;
    return toString.call(val) === '[object Array]';
  }

  isObject(val) {
    return !this.isArray(val) && typeof val === 'object' && !!val;
  }

  onSubmit() {
    this.progress = true;
    this.phoneBodyForError = this.registerForm.get('phone')?.value;

    if (this.registerForm.valid) {
      this.registerForm.get('phone')?.setValue(`${this.selectedCountry.phonecode}${this.phoneBodyForError}`);

      const transformedFormData = this.registerForm;
      if (this.isPostalSame) {
        transformedFormData.patchValue({
          'addresses[mailing][name]': this.registerForm.value['addresses[billing][name]'],
          'addresses[mailing][country]': this.registerForm.value['addresses[billing][country]'],
          'addresses[mailing][zip_code]': this.registerForm.value['addresses[billing][zip_code]'],
          'addresses[mailing][city]': this.registerForm.value['addresses[billing][city]'],
          'addresses[mailing][street]': this.registerForm.value['addresses[billing][street]'],
          'addresses[mailing][street_type]': this.registerForm.value['addresses[billing][street_type]'],
          'addresses[mailing][number]': this.registerForm.value['addresses[billing][number]'],
          'addresses[mailing][line_one]': this.registerForm.value['addresses[billing][line_one]'],
        })
      }

      if (this.registerForm.value['have_contract'] == '0') {
        transformedFormData.patchValue({
          'contract_number': null,
        })
      }

      const b = this.convertJsontoFormData(transformedFormData.value)

      this.authService.signUp(b).subscribe({
        next: () => {
          this.progress = false;
          this.router.navigate(['/login'], { queryParams: { registered: '' } });
        },
        error: errorResponse => {
          this.progress = false;
          this.errors = errorResponse.error.errors;
          this.registerForm.get('phone')?.setValue(this.phoneBodyForError);
          window.scrollTo({ top: 0, behavior: 'smooth', });
        }
      });
    } else {
      this.progress = false;
      this.errors = { phone: [this.translateService.instant('signup.phoneRequired')] }
      //this.messageService.addErrorToast(this.translateService.instant('signup.markedFields'));
      this.registerForm.get('phone')?.setValue(this.phoneBodyForError);
      window.scrollTo({ top: 0, behavior: 'smooth', });
    }
  }

  private initForm() {
    this.selectedCountry = this.systemService.countries[0];

    this.registerForm = new FormGroup({
      'name': new FormControl(null),
      'phone': new FormControl(null, [Validators.required, Validators.minLength(6), Validators.maxLength(12)]),
      'email': new FormControl(null),
      'password': new FormControl(null),
      'confirm_password': new FormControl(null),
      'last_name': new FormControl(null),
      'first_name': new FormControl(null),
      'is_company': new FormControl('0'),
      'company_name': new FormControl(null),
      'vat_number': new FormControl(null),
      'have_contract': new FormControl('0'),
      'solo_or_band': new FormControl('band'),
      'contract_number': new FormControl(null),

      'addresses[billing][name]': new FormControl(null),
      'addresses[billing][country]': new FormControl('HU'),
      'addresses[billing][zip_code]': new FormControl(null),
      'addresses[billing][city]': new FormControl(null),
      'addresses[billing][street]': new FormControl(null),
      'addresses[billing][street_type]': new FormControl(null),
      'addresses[billing][number]': new FormControl(null),
      'addresses[billing][line_one]': new FormControl(null),

      'postalSame': new FormControl(true),

      'addresses[mailing][name]': new FormControl(null),
      'addresses[mailing][country]': new FormControl('HU'),
      'addresses[mailing][zip_code]': new FormControl(null),
      'addresses[mailing][city]': new FormControl(null),
      'addresses[mailing][street]': new FormControl(null),
      'addresses[mailing][street_type]': new FormControl(null),
      'addresses[mailing][number]': new FormControl(null),
      'addresses[mailing][line_one]': new FormControl(null),

      'photo_id_front': new FormControl(null),
      'photo_id_back': new FormControl(null),
      'photo_address_front': new FormControl(null),

      'room_policy': new FormControl(false),
      'privacy_policy': new FormControl(false),
      'newsletter': new FormControl(false),
    });
  }

  onUploadIdBack(event) {
    this.registerForm.patchValue({
      photo_id_back: event.currentFiles[0],
    });

    for (let file of event.files) {
      this.uploadedFiles.push(file);
    }

    this.messageService.addInfoToast('File Uploaded');
  }

  onUploadIdFront(event) {

    this.registerForm.patchValue({
      photo_id_front: event.currentFiles[0],
    });

    for (let file of event.files) {
      this.uploadedFiles.push(file);
    }

    this.messageService.addInfoToast('File Uploaded');
  }

  onUploadAddressFront(event) {

    this.registerForm.patchValue({
      photo_address_front: event.currentFiles[0],
    });

    for (let file of event.files) {
      this.uploadedFiles.push(file);
    }

    this.messageService.addInfoToast('File Uploaded');
  }

  onPhoneMaskFocus(event) {
    event.preventDefault();
    event.target.focus();
    event.target.setSelectionRange(0, 0);
    event.target.focus();
  }
}
