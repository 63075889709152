import { Component, OnInit } from '@angular/core';
import { AppBaseComponent } from 'src/app/core/pagebase/app.base.component';
import { SeoService } from '../../services/seo.service';
import { Colors } from 'src/app/constants/colors';
import { Router } from '@angular/router';
import { BannerItem } from 'src/app/models/bannerItem.model';
import { SystemService } from 'src/app/services/system.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent extends AppBaseComponent implements OnInit {

  banners: BannerItem[];
  cards: any[];
  offers: any[];

  constructor(
    private seoService: SeoService,
    public router: Router,
    public systemService: SystemService,
    private translateService: TranslateService
  ) {
    super();
  }

  ngOnInit() {
    this.seoService.updateTitle('Kezdőlap');
    this.seoService.updateTags('Groovy home', ['lorem ipsum']);
    this.banners = this.systemService.banners.homepage ?? [
      {
        id: '1000',
        code: 'f230fh0g3',
        title: 'Ők is nálunk próbálnak!',
        subTitle: 'Gyere, próbáld ki te is próbatermeinket!',
        image: '/assets/images/a1.png',
      },
      {
        id: '1001',
        code: 'nvklal433',
        image: '/assets/images/a2.jpg',
      },
      {
        id: '1001',
        code: 'nvklal433',
        image: '/assets/images/a3.jpg',
      },
    ];

    this.cards = [
      {
        title: this.translateService.instant('home.cards.0.title'),
        description: this.translateService.instant('home.cards.0.description'),
        color: Colors.red3,
        icon: '/assets/images/date_range.svg',
      },
      {
        title: this.translateService.instant('home.cards.1.title'),
        description: this.translateService.instant('home.cards.1.description'),
        color: Colors.orange2,
        icon: '/assets/images/stop_and_play_fill.svg',
      },
      {
        title: this.translateService.instant('home.cards.2.title'),
        description: this.translateService.instant('home.cards.2.description'),
        color: Colors.green2,
        icon: '/assets/images/music.svg',
      },
    ];

    this.offers = [
      {
        title: this.translateService.instant('home.provided.0'),
        image: '/assets/images/drum.svg',
      },
      {
        title: this.translateService.instant('home.provided.1'),
        image: '/assets/images/erosito.svg',
      },
      {
        title: this.translateService.instant('home.provided.2'),
        image: '/assets/images/kottatarto.svg',
      },
      {
        title: this.translateService.instant('home.provided.3'),
        image: '/assets/images/ticket.svg',
      },
      {
        title: this.translateService.instant('home.provided.4'),
        image: '/assets/images/wifi.svg',
      },
      {
        title: this.translateService.instant('home.provided.5'),
        image: '/assets/images/drink.svg',
      },
      {
        title: this.translateService.instant('home.provided.6'),
        image: '/assets/images/ringlight.svg',
      },
      {
        title: this.translateService.instant('home.provided.7'),
        image: '/assets/images/mirror.svg',
      },
      {
        title: this.translateService.instant('home.provided.8'),
        image: '/assets/images/zongora.svg',
      },
      {
        title: this.translateService.instant('home.provided.9'),
        image: '/assets/images/Headphones_fill.svg',
      },
      {
        title: this.translateService.instant('home.provided.10'),
        image: '/assets/images/mic.svg',
      },
      {
        title: this.translateService.instant('home.provided.11'),
        image: '/assets/images/nonstop.svg',
      },
    ];
  }

  goToPolicy() {
    this.router.navigate(['policy']);
  }

  goToRegister() {
    this.router.navigate(['register']);
  }
}
