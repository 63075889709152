import { Component, ElementRef, Input, OnInit, AfterViewChecked, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BannerComponent implements OnInit, AfterViewChecked {
  @Input() title: string;
  @Input() subtitle: string;
  @Input() backgroundColor: string = '#FAFAFA';
  @Input() backgroundImage: string;
  @Input() bgCubesClass: string;

  itselfDiv: ElementRef;

  @ViewChild('itself') set itselfContent(content: ElementRef) {
    if (content) {
      this.itselfDiv = content;
    }
  }

  constructor(private renderer: Renderer2) { }

  ngOnInit(): void {
  }

  ngAfterViewChecked() {
    if (this.bgCubesClass) {
      this.renderer.addClass(this.itselfDiv.nativeElement, this.bgCubesClass);
    }
  }

  getCustomStyles() {
    const styles: any = {};

    if (this.backgroundColor) {
      styles['background-color'] = this.backgroundColor;
    }

    if (this.backgroundImage) {
      styles['background-image'] = `linear-gradient(3.67deg, #242424 -11.84%, rgba(36, 36, 36, 0) 104.76%), url(${this.backgroundImage})`;
      styles['background-repeat'] = 'no-repeat';
      styles['background-position'] = 'center';
      styles['background-size'] = 'cover';
    }

    return styles;
  }
}
