<p-blockUI [blocked]="progress"></p-blockUI>
<ng-container *ngIf="progress">
    <div id="progress">
        <p-progressSpinner styleClass="custom-spinner" strokeWidth="5"></p-progressSpinner>
    </div>
    <div class="grid pagebase {{styleClass}}">
        <div class="col-12 {{ fillWidth ? 'p-0' : 'sm:col-10 sm:col-offset-1'}}">
            <ng-content select="[skeletoncontent]"></ng-content>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="!progress">
    <div class="grid pagebase {{styleClass}}">
        <div class="col-12 {{ fillWidth ? 'p-0' : 'sm:col-10 sm:col-offset-1'}}">
            <ng-content select="[pagecontent]"></ng-content>
        </div>
    </div>
</ng-container>