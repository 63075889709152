import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SeoService } from '../../services/seo.service';
import { Colors } from 'src/app/constants/colors';
import Calendar from '@event-calendar/core';
import ResourceTimeGrid from '@event-calendar/resource-time-grid';
import Interaction from '@event-calendar/interaction';
import { AuthService } from 'src/app/services/auth.service';
import { ReservationService } from 'src/app/services/reservation.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ProgressMessageService } from 'src/app/services/progress.message.service';
import { MessageKeys } from 'src/app/constants/messageKeys';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
})
export class CalendarComponent implements OnInit, OnDestroy {
  @Input() for_user: boolean = false;

  resources: any;
  ecc: any;
  progress: boolean = false;
  messageKeys = MessageKeys;
  editId: string;

  primengCalendarModel: any;
  primengCalendarMinDate: Date = new Date();

  areYouSureDialog: boolean = false;
  areYouSureEventsArray: any;

  onHoldEventsLoaded: boolean = false;
  onSubmitted: boolean = false;

  isUsers: string = 'isUsers';

  objectHash = require('object-hash');

  constructor(
    private authService: AuthService,
    private seoService: SeoService,
    private reservationService: ReservationService,
    private messageService: ProgressMessageService,
    private translateService: TranslateService,
    public deviceService: DeviceDetectorService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  async ngOnInit(): Promise<void> {

    //this.getSlotMinTime();
    this.progress = true;
    let id = this.route.snapshot.params['id'];

    if (id) {
      this.editId = id;
      setTimeout(() => {
        this.messageService.addError(MessageKeys.Calendar, this.translateService.instant('reservation.notesWhileOnModify'), false, 'pi-info-circle');
      }, 1000);
    }

    this.authService.getRooms().subscribe((result: any) => {
      this.resources = result?.data?.map((room) => ({
        title: room.name,
        id: room.id,
        price: room.price
      }));
      const today = new Date();
      this.ecc = new Calendar({
        target: document.getElementById('ecc'),
        props: {
          plugins: [ResourceTimeGrid, Interaction],
          options: {
            allDaySlot: false,
            slotMinTime: this.getSlotMinTime(),
            datesSet: this.handleDateClick.bind(this),
            eventClick: this.handleEventClick.bind(this),
            eventDrop: this.handleEventDrop.bind(this),
            eventResize: this.handleEventResize.bind(this),
            eventStartEditable: false,
            eventDurationEditable: false,
            slotEventOverlap: false,
            longPressDelay: 100,
            eventBackgroundColor: '#33363F',
            selectBackgroundColor: Colors.red2,
            headerToolbar: {
              start: 'prev,next',
              center: '',
              end: '',
            },
            view: 'resourceTimeGridDay',
            selectable: !this.for_user,
            editable: false,
            select: this.handleDateSelect.bind(this),
            events: [],
            resources: this.resources,
            target: document.getElementById('ecc'),
          },
        },
      });
    });

    this.seoService.updateTitle('Naptár');
    this.seoService.updateTags('Groovy naptár', ['lorem ipsum']);
  }

  ngOnDestroy(): void {
    if (!this.for_user && !this.onSubmitted) {
      this.reservationService.onHoldEvents = this.ecc.getEvents().filter(e => e.extendedProps[this.isUsers]);
    }
  }

  //------------------------------------------------------------------------------------------------
  //Properties
  //------------------------------------------------------------------------------------------------

  isSubmitDisabled() {
    let events = this.ecc ? this.ecc.getEvents() : [];
    return events.findIndex(e => e.extendedProps[this.isUsers]) == -1;
  }

  //------------------------------------------------------------------------------------------------
  //Methods
  //------------------------------------------------------------------------------------------------

  // Foglalás átméretezése időben
  handleEventResize(arg) {
    if (this.deviceService.isMobile() && this.deviceService.browser == 'Firefox') {
      document.getElementById('ecc-container')?.focus();
    }

    let today: Date = this.ecc.getOption('date');
    let curr = new Date();
    if (today.getFullYear() == curr.getFullYear() && today.getMonth() == curr.getMonth() && today.getDate() == curr.getDate()) {
      today = curr;
    }

    let start = new Date(arg.event.start);
    let end = new Date(arg.event.end);

    let events: any[] = this.ecc.getEvents();
    if (arg.newResource) {
      events = events.filter(e => e.resourceIds[0] == arg.newResource.id);
    } else {
      events = events.filter(e => e.resourceIds[0] == arg.event.resourceIds[0]);
    }

    for (let i = 0; i < events.length; i++) {
      if (events[i].id != arg.event.id) {
        if (!(events[i].start >= arg.event.end || events[i].end <= arg.event.start)) {
          arg.revert();
          this.messageService.addErrorToast(this.translateService.instant('error.calendarNoOverlap'));
          return;
        }
      }
    }

    if (today.getTime() > start.getTime()) {
      start.setHours(today.getHours());
      start.setMinutes(today.getMinutes() > 30 ? 30 : 0);
      start.setSeconds(0);
    }

    if (today.getDate() > start.getDate() && start.getDate() < end.getDate()) {
      Object.assign<Date, Date>(start, end);
      start.setDate(end.getDate());
      this.setZerosOnTime(start);
    }

    if (today.getDate() < end.getDate() && start.getDate() < end.getDate()) {
      Object.assign<Date, Date>(end, start);
      end.setDate(start.getDate());
      this.setZerosOnTime(end);
    }

    let calEnd: Date = new Date(end);

    if (start.getHours() > end.getHours()) {
      this.setBeforeNoonOnTime(calEnd);
    }

    if (calEnd.getMinutes() == 59 || calEnd.getMinutes() == 29) {
      calEnd.setHours(calEnd.getMinutes() == 59 ? calEnd.getHours() + 1 : calEnd.getHours());
      calEnd.setMinutes(calEnd.getMinutes() == 29 ? 30 : 0);
      calEnd.setSeconds(0);
    }

    const newEvent = {
      id: arg.event.id,
      resourceId: arg.newResource ? arg.newResource.id : arg.event.resourceIds[0],
      title: '',
      start: start,
      end: calEnd,
      backgroundColor: Colors.red2,
      extendedProps: {
        isUsers: true,
        end: end
      },
      startEditable: true,
      durationEditable: true
    };

    this.ecc.removeEventById(arg.event.id);
    this.ecc.addEvent(newEvent);
  }

  // Foglalás áthelyezése a resources (szobák) között
  handleEventDrop(arg) {
    let today: Date = this.ecc.getOption('date');
    let curr = new Date();
    if (today.getFullYear() == curr.getFullYear() && today.getMonth() == curr.getMonth() && today.getDate() == curr.getDate()) {
      today = curr;
    }

    let start = new Date(arg.event.start);
    let end = new Date(arg.event.end);

    let events: any[] = this.ecc.getEvents();
    if (arg.newResource) {
      events = events.filter(e => e.resourceIds[0] == arg.newResource.id);
    } else {
      events = events.filter(e => e.resourceIds[0] == arg.event.resourceIds[0]);
    }

    for (let i = 0; i < events.length; i++) {
      if (events[i].id != arg.event.id) {
        if (!(events[i].start >= arg.event.end || events[i].end <= arg.event.start)) {
          arg.revert();
          this.messageService.addErrorToast(this.translateService.instant('error.calendarNoOverlap'));
          return;
        }
      }
    }

    if (today.getTime() > start.getTime()) {
      start.setHours(today.getHours());
      start.setMinutes(today.getMinutes() > 30 ? 30 : 0);
      start.setSeconds(0);
    }

    if (today.getDate() > start.getDate() && start.getDate() < end.getDate()) {
      Object.assign<Date, Date>(start, end);
      start.setDate(end.getDate());
      this.setZerosOnTime(start);
    }

    if (today.getDate() < end.getDate() && start.getDate() < end.getDate()) {
      Object.assign<Date, Date>(end, start);
      end.setDate(start.getDate());
      this.setZerosOnTime(end);
    }

    let calEnd: Date = new Date(end);

    if (start.getHours() > end.getHours()) {
      this.setBeforeNoonOnTime(calEnd);
    }

    if (calEnd.getMinutes() == 59 || calEnd.getMinutes() == 29) {
      calEnd.setHours(calEnd.getMinutes() == 59 ? calEnd.getHours() + 1 : calEnd.getHours());
      calEnd.setMinutes(calEnd.getMinutes() == 29 ? 30 : 0);
      calEnd.setSeconds(0);
    }

    const newEvent = {
      id: arg.event.id,
      resourceId: arg.newResource ? arg.newResource.id : arg.event.resourceIds[0],
      title: '',
      start: start,
      end: calEnd,
      backgroundColor: Colors.red2,
      extendedProps: {
        isUsers: true,
        end: end
      },
      startEditable: true,
      durationEditable: true
    };

    this.ecc.removeEventById(arg.event.id);
    this.ecc.addEvent(newEvent);
  }

  // Dátumválasztás / naptár lapozás esetén fut le, lekéri az adott napra a foglalásokat.
  handleDateClick(arg) {
    this.progress = true;
    const today = new Date();
    this.setZerosOnTime(today, true);

    let newStartDate = new Date(arg.start);
    this.ecc?.setOption('slotMinTime', '00:00:00');

    if (today.getTime() == newStartDate.getTime()) {
      const secToday = new Date();
      this.ecc?.setOption('slotMinTime', this.getSlotMinTime());
    }

    if (today.getTime() > newStartDate.getTime()) {
      newStartDate = today;
      const secToday = new Date();
      this.ecc?.setOption('date', today);
      this.primengCalendarModel = today;
      this.ecc?.setOption('slotMinTime', this.getSlotMinTime());
      return;
    }

    const eccEvents = this.ecc?.getEvents();
    if (eccEvents?.length > 0) {
      eccEvents.forEach((e) => {
        if (!e.extendedProps[this.isUsers])
          this.ecc.removeEventById(e.id);
      });
    }

    this.primengCalendarModel = newStartDate;

    if (this.for_user)
      this.authService
        .getReservationForUser(this.formatDateYYYY_MM_DD(newStartDate))
        .subscribe((reservations: any) => {
          reservations.forEach((el) => {
            el.editable = false;
            el.startEditable = false;
            el.durationEditable = false;
            this.ecc.addEvent(el);
          });
          this.progress = false;
        });
    else
      this.authService
        .getReservationForDay(this.formatDateYYYY_MM_DD(newStartDate), this.editId)
        .subscribe((reservations: any) => {
          reservations.forEach((el) => {

            const hash = this.objectHash(el);

            if (this.ecc.getEventById(hash)) {
              return;
            }

            el.id = hash;
            el.editable = el.in_progress;
            el.startEditable = el.in_progress;
            el.durationEditable = el.in_progress;

            if (el.in_progress) {
              let extEndDate = new Date(el.end);

              // Éjféli foglalás visszalapozásnál az újraszámolás miatti fixálás, hogy éjfélre állítja.
              if (extEndDate.getHours() == 23 && extEndDate.getMinutes() == 59 && extEndDate.getSeconds() == 59) {
                this.setZerosOnTime(extEndDate);
              }

              el.backgroundColor = Colors.red2;
              el.extendedProps = {
                isUsers: true,
                end: extEndDate,
              };
            }

            //this.ecc.removeEventById(el.id);
            this.ecc.addEvent(el);
          });

          if (!this.onHoldEventsLoaded && this.reservationService.onHoldEvents?.length > 0) {
            this.reservationService.onHoldEvents.forEach((e) => {
              this.ecc.removeEventById(e.id);
            });

            this.reservationService.onHoldEvents.forEach(e => {
              this.ecc.addEvent(e);
            });

            this.onHoldEventsLoaded = true;
          }

          this.progress = false;
        });
  }

  // Szerkeszthető időpontra kattintás esetén törli azt
  handleEventClick(arg) {
    if (arg.event.extendedProps[this.isUsers]) {
      this.ecc.removeEventById(arg.event.id);
      const i = this.reservationService.onHoldEvents.findIndex((e) => e.id == arg.event.id);
      this.reservationService.onHoldEvents.splice(i, 1);
    }
  }

  // Új dátum kijelölés esetén fut
  handleDateSelect(arg) {
    if (this.deviceService.isMobile() && this.deviceService.browser == 'Firefox') {
      document.getElementById('ecc-container')?.focus();
    }

    let today = this.ecc.getOption('date');
    let start = new Date(arg.start);
    let end = new Date(arg.end);

    if (today.getTime() > start.getTime()) {
      start.setHours(today.getHours());
      start.setMinutes(today.getMinutes() > 30 ? 30 : 0);
      start.setSeconds(0);
    }

    if (today.getDate() > start.getDate() && start.getDate() < end.getDate()) {
      Object.assign<Date, Date>(start, end);
      start.setDate(end.getDate());
      this.setZerosOnTime(start);
    }

    if (today.getDate() < end.getDate() && start.getDate() < end.getDate()) {
      Object.assign<Date, Date>(end, start);
      end.setDate(start.getDate());
      this.setZerosOnTime(end);
    }

    const currentEvents = this.ecc.getEvents();

    //Meglévő szerkesztés esetén az esetlegesen OnHold visszatöltött időpontokat eltávolítja
    if (this.editId) {
      const alreadySelected = currentEvents.filter((e) => e.editable);
      alreadySelected.forEach((e) => {
        this.ecc.removeEventById(e.id);
      });
    }

    const currentEventsLength = currentEvents.length;

    //Ha a választott időpont ütközik már meglévővel, úgy ellenőrzi, hogy saját időponttal ütközik-e, akkor összevonja, egyébként kilép és az időpont nem kerül kiválasztásra
    for (let i = 0; i < currentEventsLength; i++) {
      const currEventStart = new Date(currentEvents[i].start);
      const currEventEnd = new Date(currentEvents[i].end);

      if ((currEventStart.getTime() == new Date(arg.end).getTime() || currEventEnd.getTime() == new Date(arg.start).getTime()) &&
        currentEvents[i].resourceIds[0] === arg.resource.id && currentEvents[i].extendedProps[this.isUsers]) {
        if (currEventStart.getTime() == new Date(arg.end).getTime()) {
          end = new Date(currEventEnd);
        }

        if (currEventEnd.getTime() == new Date(arg.start).getTime()) {
          start = new Date(currEventStart);
        }

        this.ecc.removeEventById(currentEvents[i].id);
      }

      if (!(currEventStart >= new Date(arg.end) || currEventEnd <= new Date(arg.start)) &&
        currentEvents[i].resourceIds[0] === arg.resource.id) {
        return;
      }
    }

    let calEnd: Date = new Date(end);

    //Ha nap végére állítja az időpontot a felhasználó, úgy az másnap éjfélre állítódna be, ezt az API érdekében visszaállítjuk az alábbi időpontra
    if (start.getHours() > end.getHours()) {
      this.setBeforeNoonOnTime(calEnd);
    }

    const newEvent = {
      id: Math.random(),
      resourceId: arg.resource.id,
      title: '',
      start: start,
      end: calEnd,
      backgroundColor: Colors.red2,
      extendedProps: {
        isUsers: true,
        end: end
      },
      startEditable: true,
      durationEditable: true
    };
    this.ecc.addEvent(newEvent);
    this.ecc.unselect();
  }

  // Elküldi a foglalás módosítás vagy új foglalás adatait az APInak
  async handleSubmit() {
    if (!this.authService.isLoggedIn) {
      this.reservationService.onHoldEvents = this.ecc.getEvents().filter(e => e.extendedProps[this.isUsers]);
      this.router.navigate(['/login']);
      return;
    }

    this.progress = true;

    let events = this.ecc.getEvents();
    this.reservationService.onHoldEvents = [];

    const reservedData = this.toDto(events);

    try {
      if (this.editId) {
        await this.reservationService.modifyReservationData(
          this.editId,
          reservedData
        ).then(() => {
          this.progress = false;
          this.onSubmitted = true;
          this.router.navigate(['/reservation/modify', this.editId]);
        })
          .catch((error) => {
            this.handleReloadCalendar();
            this.progress = false;
            this.messageService.addError(MessageKeys.Calendar, error.error.error.message);
            return;
          });
      } else {
        await this.reservationService.postReservationData(reservedData).then(async () => {
          await this.reservationService.getReservationSummary();
          this.progress = false;
          this.onSubmitted = true;
          this.router.navigate(['/reservation']);
        })
          .catch((error) => {
            this.handleReloadCalendar();
            this.progress = false;
            this.messageService.addError(MessageKeys.Calendar, error.error.error.message);
            return;
          });
      }
    } catch (error) {
      this.progress = false;
    }
  }

  //------------------------------------------------------------------------------------------------
  //Biztos vagy benne? POPUP Methods
  //------------------------------------------------------------------------------------------------

  areYouSureEvents() {
    this.areYouSureEventsArray = this.ecc.getEvents().filter(e => e.extendedProps[this.isUsers]);

    const reservedData = this.toDto(this.areYouSureEventsArray);

    this.reservationService.previewReservation(reservedData).then((result: any) => {
      this.areYouSureEventsArray = result;
    });
  }

  areYouSureRoom(id: string) {
    return this.resources.find(r => r.id == id).title;
  }

  //------------------------------------------------------------------------------------------------
  //Internal Methods
  //------------------------------------------------------------------------------------------------

  handleReloadCalendar() {
    const eccEvents = this.ecc?.getEvents();
    if (eccEvents?.length > 0) {
      eccEvents.forEach((e) => {
        this.ecc.removeEventById(e.id);
      });
    }

    this.authService
      .getReservationForDay(this.formatDateYYYY_MM_DD(this.ecc.getOption('date')), undefined)
      .subscribe((reservations: any) => {
        reservations.forEach((element) => {
          element.editable = false;
          element.startEditable = false;
          element.durationEditable = false;
          this.ecc.addEvent(element);
        });
        this.progress = false;
      });
  }

  handleExternalDateClick(value: any) {
    let newStartDate = new Date(value);
    this.primengCalendarModel = newStartDate;
    this.ecc?.setOption('date', newStartDate);
  }

  formatDateYYYY_MM_DD(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  getSlotMinTime(): string {
    const now = new Date();
    now.setHours(now.getHours() + 1);

    let min = now.getMinutes();

    if (min > 0 && min <= 30) {
      min = 0;
    }

    if (min > 30 && min <= 45) {
      min = 30;
    }

    if (min > 45 && min <= 59) {
      now.setHours(now.getHours() + 1);
      min = 0;
    }

    now.setMinutes(min);
    now.setSeconds(0);

    return `${now.getHours()}:${now.getMinutes()}:00`;
  }

  setZerosOnTime(date: Date, isMilissecondsNeeded: boolean = false) {
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);

    if (isMilissecondsNeeded) {
      date.setMilliseconds(0);
    }
  }

  setBeforeNoonOnTime(date: Date) {
    date.setHours(23);
    date.setMinutes(59);
    date.setSeconds(59);
  }

  toDto(data: any) {
    const roomMap = {};

    return data.reduce((result, obj) => {
      const roomId = obj.resourceIds[0];

      if (obj.extendedProps[this.isUsers]) {
        if (!roomMap[roomId]) {
          roomMap[roomId] = true;
          const newObj = {
            room: roomId,
            dates: [],
          };
          result.push(newObj);
        }

        let startDate = new Date(obj.start);
        startDate.setHours(startDate.getHours() + (startDate.getTimezoneOffset() == -120 ? 2 : 1));
        let endDate = new Date(obj.extendedProps['end']);
        endDate.setHours(endDate.getHours() + (endDate.getTimezoneOffset() == -120 ? 2 : 1));
        const dateObj = {
          start: startDate.toISOString(),
          end: endDate.toISOString(),
        };
        result.find((room) => room.room === roomId).dates.push(dateObj);
      }
      return result;
    }, []);
  }
}
