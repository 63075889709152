import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { AppBaseComponent } from "src/app/core/pagebase/app.base.component";
import { Country } from "src/app/models/country.model";
import { StreetType } from "src/app/models/street-type.model";
import { AuthService } from "src/app/services/auth.service";
import { SystemService } from "src/app/services/system.service";

@Component({
    selector: 'profile-data',
    templateUrl: './profile-data.component.html',
    styleUrls: ['./profile-data.component.scss']
})
export class ProfileDataComponent extends AppBaseComponent implements OnInit {
    constructor(
        public translate: TranslateService,
        public authService: AuthService,
        public systemService: SystemService,
        public router: Router,
    ) {
        super();
        this.progressOn();
    }

    countries: Country[];
    streetTypes: StreetType[];
    form: FormGroup;
    isPostalSame: boolean = false;
    errors: null | any[];

    deleteUserDialog: boolean = false;
    deletePassword: string = "";
    deleteError: string = "";

    changePasswordDialog: boolean = false;
    changePasswordPassword: string = "";
    changePasswordPasswordNew: string = "";
    changePasswordError: string = "";

    ngOnInit() {
        this.countries = this.systemService.countries;
        this.streetTypes = this.systemService.streettypes;
        const u = this.authService.userData;
        this.isPostalSame = Object.keys(u.billing_address).reduce((equal, addressKey) => {
            return (equal === 'true' && u.billing_address[addressKey] == u.mailing_address[addressKey]) ? "true" : "false";
        }, 'true') === "true";  // weird typescript error disallowed reducer for boolean directly
        this.form = new FormGroup({
            email: new FormControl(u.email, []),
            phone: new FormControl(u.phone, []),
            last_name: new FormControl(u.last_name, []),
            first_name: new FormControl(u.first_name, []),
            company_name: new FormControl(u.company_name, []),
            vat_number: new FormControl(u.vat_number, []),

            'addresses[billing][name]': new FormControl(u.billing_address.last_name + ' ' + u.billing_address.first_name, []),
            'addresses[billing][country]': new FormControl(u.billing_address.country.iso2, []),
            'addresses[billing][zip_code]': new FormControl(u.billing_address.zip_code, []),
            'addresses[billing][city]': new FormControl(u.billing_address.city, []),
            'addresses[billing][street]': new FormControl(u.billing_address.street, []),
            'addresses[billing][street_type]': new FormControl(u.billing_address.street_type, []),
            'addresses[billing][number]': new FormControl(u.billing_address.number, []),
            'addresses[billing][line_one]': new FormControl(u.billing_address.line_one, []),

            'addresses[mailing][name]': new FormControl(u.mailing_address.last_name + ' ' + u.mailing_address.first_name, []),
            'addresses[mailing][country]': new FormControl(u.mailing_address.country.iso2, []),
            'addresses[mailing][zip_code]': new FormControl(u.mailing_address.zip_code, []),
            'addresses[mailing][city]': new FormControl(u.mailing_address.city, []),
            'addresses[mailing][street]': new FormControl(u.mailing_address.street, []),
            'addresses[mailing][street_type]': new FormControl(u.mailing_address.street_type, []),
            'addresses[mailing][number]': new FormControl(u.mailing_address.number, []),
            'addresses[mailing][line_one]': new FormControl(u.mailing_address.line_one, []),
        });

        this.progressOff();
    }

    toggleUserDelete() {
        this.deleteUserDialog = !this.deleteUserDialog;
        this.deletePassword = "";
    }

    togglePasswordReset() {
        this.changePasswordDialog = !this.changePasswordDialog;
        this.changePasswordPassword = "";
        this.changePasswordPasswordNew = "";
    }

    updateData = async () => {
        const v = this.form.value;
        const billingNameParts = v['addresses[billing][name]'].split(' ');
        const mailingNameParts = v['addresses[mailing][name]'].split(' ');
        const billingFirstName = billingNameParts[billingNameParts.length - 1];
        const billingLastName = billingNameParts[0];
        const mailingFirstName = mailingNameParts[mailingNameParts.length - 1];
        const mailingLastName = mailingNameParts[0];
        const billingCountryId = this.countries.find(c => c.iso2 == v['addresses[billing][country]'])!.id;
        const mailingCountryId = this.countries.find(c => c.iso2 == v['addresses[mailing][country]'])!.id;

        const data = {
            "email": v.email,
            "first_name": v.first_name,
            "last_name": v.last_name,
            "company_name": v.company_name,
            "vat_number": v.vat_number,
            "addresses": [
                {
                    "first_name": billingFirstName,
                    "last_name": billingLastName,
                    "country_id": billingCountryId,
                    "zip_code": v['addresses[billing][zip_code]'],
                    "city": v['addresses[billing][city]'],
                    "street": v['addresses[billing][street]'],
                    "street_type": v['addresses[billing][street_type]'],
                    "number": v['addresses[billing][number]'],
                    "line_one": v['addresses[billing][line_one]'],
                    "type": "billing"
                },
                {
                    "first_name": mailingFirstName,
                    "last_name": mailingLastName,
                    "country_id": mailingCountryId,
                    "zip_code": v['addresses[mailing][zip_code]'],
                    "city": v['addresses[mailing][city]'],
                    "street": v['addresses[mailing][street]'],
                    "street_type": v['addresses[mailing][street_type]'],
                    "number": v['addresses[mailing][number]'],
                    "line_one": v['addresses[mailing][line_one]'],
                    "type": "mailing",
                }
            ]
        };

        await this.authService.updateData(data);
    }

    async changePassword() {
        await this.authService.changePassword(this.changePasswordPassword, this.changePasswordPasswordNew);
        this.changePasswordDialog = false;
    }

    async confirmDelete() {
        await this.authService.deleteProfile();
        await this.authService.logout();
        this.router.navigateByUrl('/');
    }
}