import { Component, Input } from '@angular/core';
import { MenuService } from 'src/app/services/menu.service';
import { SystemService } from 'src/app/services/system.service';

@Component({
  selector: 'app-menubar',
  templateUrl: 'app.menu.component.html',
})
export class AppMenuComponent {
  //------------------------------------------------------------------------------------------------
  //Variables
  //------------------------------------------------------------------------------------------------

  @Input() responsive: boolean;

  //------------------------------------------------------------------------------------------------
  //Constructor
  //------------------------------------------------------------------------------------------------

  constructor(
    public menuService: MenuService,
    public systemService: SystemService
  ) {
    this.responsive = false;
  }
}
