import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem } from 'primeng/api';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  //------------------------------------------------------------------------------------------------
  //Variables
  //------------------------------------------------------------------------------------------------

  model: MenuItem[];
  userMenuModel: MenuItem[] = [];

  menuToggled: boolean = false;
  showMenu: boolean = true;

  //------------------------------------------------------------------------------------------------
  //Constructor
  //------------------------------------------------------------------------------------------------

  constructor(
    private authService: AuthService,
    private translateService: TranslateService
  ) {
    this.authService.userSub.subscribe(() => this.buildUserMenuModel());
  }

  //------------------------------------------------------------------------------------------------
  //Methods
  //------------------------------------------------------------------------------------------------

  toggleMenu() {
    this.menuToggled = !this.menuToggled;
  }

  toggleLanguage(lang: string) {
    this.translateService.use(lang).subscribe(() => {});
  }

  buildUserMenuModel() {
    this.model = [
      {
        label: this.translateService.instant('menu.about_us').toUpperCase(),
        routerLink: ['about-us'],
        command: () => this.toggleMenu(),
      },
      {
        label: this.translateService.instant('menu.studiorecording').toUpperCase(),
        routerLink: ['studiorecording'],
        command: () => this.toggleMenu(),
      },
      {
        label: this.translateService.instant('menu.rooms').toUpperCase(),
        routerLink: ['rooms'],
        command: () => this.toggleMenu(),
      },
      /*       {
              label: translateService.instant('menu.our_prices').toUpperCase(),
              routerLink: ['/prices'],
              command: () => this.toggleMenu(),
            }, */
      {
        label: this.translateService.instant('menu.reservation').toUpperCase(),
        routerLink: ['calendar'],
        command: () => this.toggleMenu(),
      },
      {
        label: this.translateService.instant('menu.policy').toUpperCase(),
        routerLink: ['policy'],
        command: () => this.toggleMenu(),
      },
      {
        label: this.translateService.instant('menu.groovy_news').toUpperCase(),
        target: '_blank',
        url: 'https://www.facebook.com/groovystudio.hu',
        command: () => this.toggleMenu(),
      },
      {
        label: this.translateService.instant('menu.contact').toUpperCase(),
        routerLink: ['contact'],
        command: () => this.toggleMenu(),
      },
    ];

    if (this.authService.isLoggedIn) {
      this.model.splice(3, 0, {
        label: this.translateService.instant('menu.profile').toUpperCase(),
        routerLink: ['profile'],
        command: () => this.toggleMenu(),
      });
    }
  }
}
