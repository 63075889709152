import { Injectable } from "@angular/core";
import { MessageService } from "primeng/api";
import { MessageKeys } from "../constants/messageKeys";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
    providedIn: 'root',
  })
export class ProgressMessageService {

    //------------------------------------------------------------------------------------------------
    //Constructor
    //------------------------------------------------------------------------------------------------

    constructor(
        private messageService: MessageService,
        private translateService: TranslateService
    ) {
    }

    //------------------------------------------------------------------------------------------------
    //Methods
    //------------------------------------------------------------------------------------------------

    addErrorToast(detail: string): void {
        this.messageService.add({ severity: 'error', summary: this.translateService.instant('messageToast.error'), detail: detail });
    }

    addSuccessToast(detail: string): void {
        this.messageService.add({ severity: 'success', summary: 'OK', detail: detail });
    }

    addInfoToast(detail: string): void {
        this.messageService.add({ severity: 'info', summary: this.translateService.instant('messageToast.info'), detail: detail });
    }

    addError(messageKeys: MessageKeys, summary: string, closable: boolean = true, icon?: string): void {
        if (icon)
            this.messageService.add({ key: messageKeys, severity: 'error2', summary: summary, closable: closable, icon: icon });
        else
            this.messageService.add({ key: messageKeys, severity: 'error', summary: summary, closable: closable });
    }

    addSuccess(messageKeys: MessageKeys, summary: string): void {
        this.messageService.add({ key: messageKeys, severity: 'success', summary: summary });
    }

    addInfo(messageKeys: MessageKeys, summary: string): void {
        this.messageService.add({ key: messageKeys, severity: 'info', summary: summary });
    }

    clear(messageKeys: MessageKeys) {
        this.messageService.clear(messageKeys);
    }
}
