export const environment = {
  production: true,

  selfUrl: window.location.origin,
  apiUrl: 'https://api.groovy.hu/',
  mainTitle: 'Groovy',

  idp: {
    issuer: 'https://api.groovy.hu',
    loginUrl: '/api/login',
    logoutUrl: 'api/auth/me/logout',
    tokenRefreshUrl: '/oauth/token/refresh',
    client: 'f2ba1cf4-6fa7-4927-a300-32c7141e747f',
    clientSecret: 'L2g2LPD0nATfIzsqiGbA9AWJFJ2g9kiaAQAUgOIn',
  },

  endpoints: {
    getApiRoutes: 'api/routes',
  },

  captcha: {
    siteKey: 'SITEKEY',
  },
};
