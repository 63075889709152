<div class="layout-wrapper">
  <app-topbar></app-topbar>
  <div class="hidden sm:block grid app-menubar">
    <div class="col-10 col-offset-1">
      <app-menubar></app-menubar>
    </div>
  </div>
  <div class="block sm:hidden app-menubar mt-2">
    <app-menubar [responsive]="true"></app-menubar>
</div>
  <div class="layout-main">
    <div class="layout-content">
      <router-outlet></router-outlet>
    </div>
  </div>
  <div class="layout-footer">
    <app-footer></app-footer>
  </div>
</div>
