<div class="profile-reservations-container">
  <p-table [value]="reservations" responsiveLayout="stack" [breakpoint]="'960px'">
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th>{{ "profile.reservation_id" | translate }}</th>
        <th>{{ "profile.reservation_date" | translate }}</th>
        <th>{{ "profile.reservation_timeslot" | translate }}</th>
        <th>{{ "profile.reservation_room" | translate }}</th>
        <th>{{ "profile.reservation_equipment" | translate }}</th>
        <th>{{ "profile.reservation_is_contractual" | translate }}</th>
        <th></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-reservation let-columns="columns">
      <tr>
        <td>
          <span class="p-column-title">{{ "profile.reservation_id" | translate }}</span>{{ reservation.reference }}
        </td>
        <td>
          <span class="p-column-title">{{ "profile.reservation_date" | translate }}</span>{{ reservation.date }}
        </td>
        <td>
          <span class="p-column-title">{{ "profile.reservation_timeslot" | translate }}</span>{{ reservation.time_slot
          }}
        </td>
        <td>
          <span class="p-column-title">{{ "profile.reservation_room" | translate }}</span>{{ reservation.room_name }}
        </td>
        <td>
          <span class="p-column-title">{{ "profile.reservation_equipment" | translate }}</span>{{ reservation.equipment
          }}
        </td>
        <td class="text-center">
          <span class="p-column-title">{{ "profile.reservation_is_contractual" | translate }}</span>
          <i class="pi pi-check" *ngIf="reservation.contract_time_slot"></i>
        </td>
        <td>
          <button pButton class="bg-teal-200" [label]="'reservation.modify' | translate"
            [routerLink]="['/reservation/edit/', reservation.id]" *ngIf="reservation.is_modifiable"></button>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>