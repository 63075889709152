import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SystemService } from 'src/app/services/system.service';

interface IContact {
  text: string;
  iconURL: string;
}

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnInit {
  openHours: IContact[] = [];
  contacts: IContact[];

  constructor(
    public systemService: SystemService,
    public translate: TranslateService
  ) { }

  ngOnInit(): void {
    let settings = this.systemService.settings;
    this.contacts = [
      {
        text: settings.contact.email,
        iconURL: '/assets/images/contact/mail.svg',
      },
      {
        text: settings.contact.phone + ' ' + settings.contact.extra,
        iconURL: '/assets/images/contact/phone.svg',
      },
      {
        text: '1056 Budapest, Régi Posta u. 11.',
        iconURL: '/assets/images/contact/pin.svg',
      },
      {
        text: this.translate.instant('contact.facebook'),
        iconURL: '/assets/images/contact/facebook.svg',
      },
    ];
    this.formatHours();
  }
  private formatHours() {
    let l_ = Object.keys(this.systemService.settings.openHours).length;
    let hours: any[] = [];
    let days: string[] = [
      'monday',
      'tuesday',
      'wednesday',
      'thursday',
      'friday',
      'saturday',
      'sunday',
    ];
    let lang: any = localStorage.getItem('locale');
    this.translate.setDefaultLang(lang);
    for (let i = 0; i < l_ / 2; i++) {
      let temp_ = 'day' + i + '_start';
      let temp2_ = 'day' + i + '_end';
      hours.push({
        text:
          this.translate.instant('days.' + days[i]) +
          ': ' +
          this.systemService.settings.openHours[temp_] +
          '-' +
          this.systemService.settings.openHours[temp2_],
        iconURL: '/assets/images/contact/clock.svg',
      });
    }
    this.openHours.push(...hours);
  }
}
