<app-pagebase>
  <div pagecontent class="grid">
    <div class="sm:col"></div>
    <div class="col-0 sm:col-7 pagebase-bg mt-3 mb-3 border-round">
      <div class="grid">
        <div class="sm:col"></div>
        <div class="col-12 sm:col-11 text-center">
          <img src="assets/images/groovy_logo.svg" class="mt-2" width="80" />
          <div class="text-base font-bold">A keresett oldal nem található!</div>
          <p>
            Hiba történt, a keresett oldal nem található vagy már nem létezik.
          </p>
        </div>
        <div class="sm:col"></div>
      </div>
    </div>
    <div class="sm:col"></div>
  </div>
</app-pagebase>
