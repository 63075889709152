import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SystemService } from './system.service';

@Injectable({
  providedIn: 'root',
})
export class RoomsService {
  //------------------------------------------------------------------------------------------------
  //Variables
  //------------------------------------------------------------------------------------------------

  rooms: any[] = [];

  //------------------------------------------------------------------------------------------------
  //Constructor
  //------------------------------------------------------------------------------------------------

  constructor(
    private systemService: SystemService,
    private http: HttpClient) { }

  //------------------------------------------------------------------------------------------------
  //Methods
  //------------------------------------------------------------------------------------------------


  list() {
    return lastValueFrom(
      this.http.get(`${environment.apiUrl}${this.systemService.routes['api.rooms.list'].uri}`)
    ).then((result: any) => {
      this.rooms = result.data;
    });
  }
}
