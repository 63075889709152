import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { AuthService } from "src/app/services/auth.service";
import { IMyReservation, ReservationService } from "src/app/services/reservation.service";

@Component({
    selector: 'profile-data',
    templateUrl: './profile-reservations-component.html',
    styleUrls: ['./profile-reservations-component.scss']
})
export class ProfileReservationsComponent implements OnInit {

    constructor(
        public translate: TranslateService,
        public authService: AuthService,
        public reservationService: ReservationService,
    ) { }

    reservations: IMyReservation[] = [];

    async ngOnInit(): Promise<void> {
        this.reservations = (await this.reservationService.getUserReservation()).data;
    }

    get contractualUser() {
        return (this.authService.userData?.role?.handle ?? "").indexOf("with_contract") != -1;
    }

}