import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { AppMainComponent } from '../core/main/app.main.component';
import { AppNotfoundComponent } from '../pages/404/app.notfound.component';
import { HomeComponent } from '../pages/home/home.component';
import { LoginComponent } from '../pages/auth/login.component';
import { RegisterComponent } from '../pages/auth/register.component';
import { RoomsComponent } from '../pages/rooms/rooms.component';
import { PricesComponent } from '../pages/prices/prices.component';
import { CalendarComponent } from '../pages/calendar/calendar.component';
import { AboutUsComponent } from '../pages/about-us/about-us.component';
import { PolicyComponent } from '../pages/policy/policy.component';
import { ContactComponent } from '../pages/contact/contact.component';
import { ReservationPageComponent } from '../pages/reservation-page/reservation-page.component';
import { ReservationSummaryPageComponent } from '../pages/reservation-summary-page/reservation-summary-page.component';
import { ProfilePageComponent } from '../pages/profile/profile-page.component';
import { ProfileCalendarComponent } from '../pages/profile/calendar/profile-calendar.componet';
import { ProfileReservationsComponent } from '../pages/profile/reservations/profile-reservations-component';
import { ProfileDataComponent } from '../pages/profile/data/profile-data.component';
import { AuthGuard } from '../guards/auth.guard';
import { ImpressumComponent } from '../pages/impressum/impressum.component';
import { FaqComponent } from '../pages/faq/faq.component';
import { ResetPasswordComponent } from '../pages/auth/reset.password.component';
import { StudiorecordingComponent } from '../pages/studiorecording/studiorecording.component';

@NgModule({
  imports: [
    RouterModule.forRoot(
      [
        {
          path: '',
          component: AppMainComponent,
          children: [
            { path: '', redirectTo: '/home', pathMatch: 'full' },
            { path: 'home', component: HomeComponent },
            { path: '404', component: AppNotfoundComponent },
            { path: 'login', component: LoginComponent },
            { path: 'register', component: RegisterComponent },
            { path: 'rooms', component: RoomsComponent },
            { path: 'prices', component: PricesComponent },
            { path: 'calendar', component: CalendarComponent },
            { path: 'about-us', component: AboutUsComponent },
            { path: 'policy', component: PolicyComponent },
            { path: 'faq', component: FaqComponent },
            { path: 'contact', component: ContactComponent },
            { path: 'legalnotice', component: ImpressumComponent },
            { path: 'studiorecording', component: StudiorecordingComponent },
            {
              path: 'reservation',
              component: ReservationPageComponent,
              canActivate: [AuthGuard],
            },
            {
              path: 'reservation/modify/:id',
              component: ReservationPageComponent,
              canActivate: [AuthGuard],
            },
            {
              path: 'reservation/edit/:id',
              component: CalendarComponent,
              canActivate: [AuthGuard],
            },
            {
              path: 'reservation-summary',
              component: ReservationSummaryPageComponent,
              canActivate: [AuthGuard],
            },
            {
              path: 'profile',
              component: ProfilePageComponent,
              children: [
                {
                  path: '',
                  redirectTo: '/profile/reservation',
                  pathMatch: 'full',
                },
                //{ path: 'calendar', component: ProfileCalendarComponent },
                {
                  path: 'reservation',
                  component: ProfileReservationsComponent,
                },
                { path: 'data', component: ProfileDataComponent },
              ],
              canActivate: [AuthGuard],
            },
            { path: 'password_reset', component: ResetPasswordComponent },
          ],
        },
        { path: '**', redirectTo: '/404' },
      ],
      {
        scrollPositionRestoration: 'enabled',
        scrollOffset: [0, 0],
        anchorScrolling: 'enabled',
        relativeLinkResolution: 'corrected',
      }
    ),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
