<div class="grid surface-900 topbar">
  <div *ngIf="!authService.isLoggedIn" class="col-12 sm:col-10 sm:col-offset-1 flex flex-row justify-content-end">
    <div class="p-2">
      <a [routerLink]="['register']"><i class="pi pi-user mr-2"></i>{{ "base.signup" | translate
        }}</a>
    </div>
    <div class="p-2 text-0">|</div>
    <div class="p-2">
      <a [routerLink]="['login']"><i class="pi pi-arrow-left mr-2"></i>{{ "base.signin" | translate
        }}</a>
    </div>
    <div class="p-2 text-0">
      |
    </div>
    <div class="p-2">
      <a class="mr-2" (click)="translateLanguageTo('hu',1)">HU</a>
      <a (click)="translateLanguageTo('en',1)">EN</a>
    </div>
  </div>
  <div *ngIf="authService.isLoggedIn" class="col-12 sm:col-10 sm:col-offset-1 flex justify-content-end">
    <div class="p-2 welcome-text mb-1">
      <a [routerLink]="['profile']">
        <i class="pi pi-user mr-2"></i>
        {{ "base.user_welcome" | translate }} {{ authService.userData.name }}!
      </a>
    </div>
    <div class="p-2 text-0">|</div>
    <div class="p-2">
      <a class="mr-2" (click)="translateLanguageTo('hu', 1)">HU</a>
      <a (click)="translateLanguageTo('en', 1)">EN</a>
    </div>
  </div>
</div>