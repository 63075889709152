<div class="">
  <div class="profile-page-container">
    <div class="profile-page-grid grid">
      <div class="col-12 md:col-4 text-center">
        <h1 class="title mt-4 mb-2">
          {{ "profile.title" | translate }}
        </h1>
      </div>
      <div class="col-12 md:col-8"></div>
      <div class="col-12 md:col-4">
        <div class="flex flex-column align-items-center">
          <div class="logged-in-text mb-4 pl-2">
            {{ "profile.logged_in_as" | translate }}
            <b>{{ authService.userData.email }}</b>
          </div>
          <div class="sidebar-container">
            <!-- <a [routerLink]="['/profile/calendar']">
              <div
                class="menu-item {{
                  router.url.includes('/profile/calendar')
                    ? 'active'
                    : ''
                }}"
              >
                <i class="pi pi-calendar-times ml-2 mr-4"></i>
                {{ "profile.calendar" | translate }}
              </div>
            </a> -->
            <a [routerLink]="['/profile/reservation']">
              <div class="menu-item {{
                  router.url.includes('/profile/reservation')
                    ? 'active'
                    : ''
                }}">
                <i class="pi pi-check-square ml-2 mr-4"></i>
                {{ "profile.reservations" | translate }}
              </div>
            </a>
            <a [routerLink]="['/profile/data']">
              <div class="menu-item {{
                  router.url.includes('/profile/data')
                    ? 'active'
                    : ''
                }}">
                <i class="pi pi-user ml-2 mr-4"></i>
                {{ "profile.my_data" | translate }}
              </div>
            </a>
            <a (click)="logout()">
              <div class="menu-item">
                <i class="pi pi-arrow-right ml-2 mr-4"></i>
                <span>{{ "profile.logout" | translate }}</span>
              </div>
            </a>
          </div>
        </div>
      </div>
      <div class="col-12 md:col-8 p-0 sm:p-4">
        <div class="logged-in-text"></div>
        <router-outlet></router-outlet>
      </div>
    </div>
    <app-banner backgroundColor="#dbf5b0" [title]="'banner.oneRehearsalArea' | translate"
      [subtitle]="'banner.oneRehearsalArea2' | translate" bgCubesClass="banner-cubes5">
      <div>
        <button pButton class="button-beige mt-2" [label]="'base.room_usage' | translate"
          (click)="goToReservationPage()"></button>
      </div>
    </app-banner>
  </div>
</div>