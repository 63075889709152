<p-blockUI [blocked]="progress"></p-blockUI>
<div id="ecc-container"></div>
<div>
  <p-toast></p-toast>
  <p-messages [key]="messageKeys.Calendar"></p-messages>
  <div *ngIf="progress" class="mb-3 sm:mx-8">
    <p-progressBar mode="indeterminate"></p-progressBar>
  </div>
  <div class="calendar-container sm:mx-8">
    <p-calendar *ngIf="!progress" [(ngModel)]="primengCalendarModel" [minDate]="primengCalendarMinDate"
      [showIcon]="true" [touchUI]="deviceService.isMobile()" [readonlyInput]="deviceService.isMobile()" dateFormat="yy-mm-dd"
      (onSelect)="handleExternalDateClick($event)" appendTo="body" inputStyleClass="font-bold"
      styleClass="w-full translate-x-75 sm:w-auto my-2 sm:my-0 sm:absolute"></p-calendar>
    <button *ngIf="!for_user && !progress" [disabled]="isSubmitDisabled()"
      (click)="areYouSureEvents();areYouSureDialog=true" pButton
      class="button-red2 calendar-reserve-button w-full sm:w-auto my-2 sm:my-0 sm:absolute"
      [label]="'base.goToReservation' | translate"></button>
    <div id="ecc"></div>
  </div>
  <app-banner *ngIf="!for_user && !progress" backgroundColor="#FFF6BA" [title]="'banner.checkOurRooms' | translate"
    bgCubesClass="banner-cubes5" [subtitle]="'banner.oneRehearsalArea2' | translate">
    <div>
      <button pButton class="button-beige" [label]="'base.rooms' | translate" [routerLink]="['/rooms']"></button>
    </div>
  </app-banner>
</div>
<p-dialog [showHeader]="false" [modal]="true" [blockScroll]="true" [resizable]="false" [(visible)]="areYouSureDialog"
  [breakpoints]="{'768px': '100vw'}" [style]="{width: '50vw'}">
  <div class="flex flex-column justify-content-center pt-3 relative overflow-hidden">
    <div class="flex flex-column pt-3">
        <div class="flex">
          <div class="flex justify-content-center ml-3 w-full">
            <b>{{'reservation.areYouSureTitle' | translate}}</b>
          </div>
          <div class="flex justify-content-end w-1rem">
            <a class="text-900 float-right" (click)="areYouSureDialog=false"><i class="pi pi-times"></i></a>
          </div>
        </div>
        <div class="flex justify-content-center w-full sm:w-10 mx-auto">
          <p>{{'reservation.areYouSureDetails' | translate}}</p>
        </div>
        <div *ngIf="areYouSureEventsArray" class="w-full sm:w-10 mx-auto">
          <div class="grid" *ngFor="let event of areYouSureEventsArray.data">
            <div class="col-3">
              {{ areYouSureRoom(event.room) }}
            </div>
            <div class="col-9 grid">
              <ng-container *ngFor="let date of event.dates">
                <div class="col-9">
                  {{ date.start | date: 'YYYY.MM.dd HH:mm':'+0000' }} - {{ date.end | date: 'HH:mm':'+0000'}}
                </div>
                <div class="col-3 flex justify-content-end">
                  {{ date.price }} Ft
                </div>
              </ng-container>
            </div>

          </div>
          <div class="flex justify-content-end font-bold">
            {{'reservation.sum' | translate}}: {{areYouSureEventsArray.sum}} Ft
          </div>
        </div>
        <div class="flex flex-column sm:flex-row align-items-center sm:justify-content-end">
          <p-button [label]="'base.goToReservation' | translate" (click)="areYouSureDialog=false;handleSubmit()"
            styleClass="mt-2"></p-button>
          <p-button [label]="'base.modify' | translate | uppercase" (click)="areYouSureDialog=false"
            styleClass="p-button-text p-button-danger mt-2"></p-button>
        </div>
    </div>
  </div>
</p-dialog>