<div class="grid" [formGroup]="form">
    <div class="sm:col-12 md:col-8 md:col-offset-2 xl:col-6 xl:col-offset-3 pagebase-bg mt-3 mb-3 border-round">
        <div class="grid">
            <div class="col-12 sm:col-8 sm:col-offset-2 p-4">
                <div class="text-center">
                    <h2>{{'resetPassword.title' | translate}}</h2>
                    <p class="mb-5">{{'resetPassword.details' | translate}}</p>
                </div>
                <p-messages [key]="messageKeys.ResetPass"></p-messages>
                <div class="mb-3">
                    <label for="password" class="block text-left">{{ 'base.password' | translate }}*</label>
                    <p-password id="password" [feedback]="false" [toggleMask]="true" formControlName="password"
                        aria-describedby="password-help" [style]="{ width: '100%' }" [inputStyle]="{ width: '100%' }"></p-password>
                </div>
                <div class="mb-5">
                    <label for="password2" class="block text-left">{{ 'base.password2' | translate }}*</label>
                    <p-password id="password2" [feedback]="false" [toggleMask]="true"
                        formControlName="password_confirmation" [style]="{ width: '100%' }" [inputStyle]="{ width: '100%' }">
                    </p-password>
                </div>
                <p-button [label]="'base.save' | translate | uppercase" (onClick)="submit()"
                    styleClass="mb-3"></p-button>
            </div>
        </div>
    </div>
</div>