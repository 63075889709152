<div *ngIf="!progress" class="profile-data-container p-3 sm:p-0">
    <div class="profile-data-grid" [formGroup]="form">
        <div class="fullwidth">{{ "profile.data.title" | translate }}</div>
        <div class="divider"></div>

        <div class="cell">
            <label for="email">{{ 'base.email' | translate }}<span class="text-red-700">*</span></label>
            <input pInputText type="text" id="email" class="w-full" placeholder="{{ 'base.email' | translate }}"
                formControlName="email" />
            <div class="bg-pink-600 text-white p-1" *ngIf="errors && errors['email']">{{ errors['email'] }}</div>
        </div>
        <div class="cell">
            <label for="phone">{{ 'base.phone' | translate }}<span class="text-red-700">*</span></label>
            <input type="text" id="phone" pInputText class="w-full" formControlName="phone">
            <div class="bg-pink-600 text-white p-1" *ngIf="errors && errors['phone']">{{ errors['phone'] }}</div>
        </div>
        <div class="cell">
            <label for="last_name">{{ 'base.lastname' | translate }}<span class="text-red-700">*</span></label>
            <input pInputText type="text" id="last_name" class="w-full" placeholder="{{ 'base.lastname' | translate }}"
                formControlName="last_name" />
            <div class="bg-pink-600 text-white p-1" *ngIf="errors && errors['lastName']">{{ errors['lastName'] }}</div>
        </div>
        <div class="cell">
            <label for="first_name">{{ 'base.firstname' | translate }}<span class="text-red-700">*</span></label>
            <input pInputText type="text" id="first_name" class="w-full"
                placeholder="{{ 'base.firstname' | translate }}" formControlName="first_name" />
            <div class="bg-pink-600 text-white p-1" *ngIf="errors && errors['firstName']">{{ errors['firstName'] }}
            </div>
        </div>
        <div class="cell">
            <label for="company_name">{{ 'base.company_name' | translate }}</label>
            <input type="text" id="company_name" pInputText class="w-full" formControlName="company_name">
            <div class="bg-pink-600 text-white p-1" *ngIf="errors && errors['companyName']">{{ errors['companyName'] }}
            </div>
        </div>
        <div class="cell">
            <label for="vat_number">{{ 'base.vat_number' | translate }}</label>
            <input type="text" id="vat_number" pInputText class="w-full" formControlName="vat_number">
            <div class="bg-pink-600 text-white p-1" *ngIf="errors && errors['vatNumber']">{{ errors['vatNumber'] }}
            </div>
        </div>

        <div class="divider"></div>

        <div class="fullwidth">
            {{ "billing.address" | translate }}
        </div>
        <div class="cell">
            <label for="addresses[billing][name]">{{ 'billing.name' | translate }}<span
                    class="text-red-700">*</span></label>
            <input type="text" id="addresses[billing][name]" class="w-full" pInputText
                formControlName="addresses[billing][name]">
        </div>
        <div class="cell">
            <label for="addresses[billing][country]">{{ 'address.country' | translate }}</label>
            <p-dropdown id="addresses[billing][country]" class="w-full" styleClass="w-full p-component p-inputwrapper"
                [options]="countries" optionLabel="name" optionValue="iso2" [filter]="true" filterBy="name"
                [showClear]="true" placeholder="{{ 'address.SelectCountry' | translate }}"
                formControlName="addresses[billing][country]">
            </p-dropdown>
        </div>
        <div class="cell">
            <label for="addresses[billing][zip_code]">{{ 'billing.zip' | translate }}<span
                    class="text-red-700">*</span></label>
            <input type="text" id="addresses[billing][zip_code]" class="w-full" pInputText
                formControlName="addresses[billing][zip_code]">
        </div>
        <div class="cell">
            <label for="addresses[billing][city]">{{ 'billing.city' | translate }}<span
                    class="text-red-700">*</span></label>
            <input type="text" id="addresses[billing][city]" class="w-full" pInputText
                formControlName="addresses[billing][city]">
        </div>
        <div class="cell">
            <label for="addresses[billing][street]">{{ 'billing.street' | translate }}<span
                    class="text-red-700">*</span></label>
            <input type="text" id="addresses[billing][street]" class="w-full" pInputText
                formControlName="addresses[billing][street]">
        </div>
        <div class="cell">
            <label for="addresses[billing][street_type]">{{ 'billing.streetType' | translate }}<span
                    class="text-red-700">*</span></label>
            <input type="text" id="addresses[billing][street_type]" class="w-full" pInputText
                formControlName="addresses[billing][street_type]">
        </div>
        <div class="cell">
            <label for="addresses[billing][number]">{{ 'billing.houseNumber' | translate }}<span
                    class="text-red-700">*</span></label>
            <input type="text" id="addresses[billing][number]" class="w-full" pInputText
                formControlName="addresses[billing][number]">
        </div>
        <div class="cell">
            <label for="addresses[billing][line_one]">{{ 'billing.flat' | translate }}<span
                    class="text-red-700">*</span></label>
            <input type="text" id="addresses[billing][line_one]" class="w-full" pInputText
                formControlName="addresses[billing][line_one]">
        </div>

        <div class="divider"></div>

        <div class="fullwidth">
            {{ "postal.address" | translate }}
        </div>
        <div class="fullwidth">
            <p-checkbox class="mr-2" [binary]="true" id="isPostalSame" [ngModelOptions]="{standalone: true}"
                [(ngModel)]="isPostalSame"></p-checkbox>
            <label for="isPostalSame">{{ 'postal.same' | translate }}</label>
        </div>
        <ng-container *ngIf="!isPostalSame">
            <div class="cell">
                <label for="addresses[mailing][name]">{{ 'billing.name' | translate }}<span
                        class="text-red-700">*</span></label>
                <input type="text" id="addresses[mailing][name]" class="w-full" pInputText
                    formControlName="addresses[mailing][name]">
            </div>
            <div class="cell">
                <label for="addresses[mailing][country]">{{ 'address.country' | translate }}</label>
                <p-dropdown id="addresses[mailing][country]" class="w-full"
                    styleClass="w-full p-component p-inputwrapper" [options]="countries" optionLabel="name"
                    optionValue="iso2" [filter]="true" filterBy="name" [showClear]="true"
                    placeholder="{{ 'address.SelectCountry' | translate }}"
                    formControlName="addresses[mailing][country]">
                </p-dropdown>
            </div>
            <div class="cell">
                <label for="addresses[mailing][zip_code]">{{ 'billing.zip' | translate }}<span
                        class="text-red-700">*</span></label>
                <input type="text" id="addresses[mailing][zip_code]" class="w-full" pInputText
                    formControlName="addresses[mailing][zip_code]">
            </div>
            <div class="cell">
                <label for="addresses[mailing][city]">{{ 'billing.city' | translate }}<span
                        class="text-red-700">*</span></label>
                <input type="text" id="addresses[mailing][city]" class="w-full" pInputText
                    formControlName="addresses[mailing][city]">
            </div>
            <div class="cell">
                <label for="addresses[mailing][street]">{{ 'billing.street' | translate }}<span
                        class="text-red-700">*</span></label>
                <input type="text" id="addresses[mailing][street]" class="w-full" pInputText
                    formControlName="addresses[mailing][street]">
            </div>
            <div class="cell">
                <label for="addresses[mailing][street_type]">{{ 'billing.streetType' | translate }}<span
                        class="text-red-700">*</span></label>
                <input type="text" id="addresses[mailing][street_type]" class="w-full" pInputText
                    formControlName="addresses[mailing][street_type]">
            </div>
            <div class="cell">
                <label for="addresses[mailing][number]">{{ 'billing.houseNumber' | translate }}<span
                        class="text-red-700">*</span></label>
                <input type="text" id="addresses[mailing][number]" class="w-full" pInputText
                    formControlName="addresses[mailing][number]">
            </div>
            <div class="cell">
                <label for="addresses[mailing][line_one]">{{ 'billing.flat' | translate }}<span
                        class="text-red-700">*</span></label>
                <input type="text" id="addresses[mailing][line_one]" class="w-full" pInputText
                    formControlName="addresses[mailing][line_one]">
            </div>

            <div class="divider"></div>
            <div class="cell">
                <div>{{ "profile.data.user_type" | translate }}</div>
                <div class="faded">{{ authService.userData.role.name }}</div>
            </div>
            <div class="cell">
                <div>{{ "profile.data.contract_id" | translate }}</div>
                <div class="faded">{{ "" }}</div>
            </div>
            <div class="cell">
                <div>{{ "base.contracted" | translate }}</div>
                <div class="faded">{{ authService.userData.role.handle.includes("wo_contract") ? ("base.yes" |
                    translate) : ("base.no" | translate) }}</div>
            </div>
            <div class="cell">
                <div>{{ "base.soloOrBand" | translate }}</div>
                <div class="faded">{{ authService.userData.role.handle.includes("orchestral") ? ("base.band" |
                    translate) : ("base.solo" | translate) }}</div>
            </div>
            <div class="cell">
                <div>{{ "profile.data.contract_days" | translate }}</div>
                <div class="faded">{{ "" }}</div>
            </div>
            <div class="cell">
                <div>{{ "profile.data.contract_timeslots" | translate }}</div>
                <div class="faded">{{ "" }}</div>
            </div>
            <div class="cell">
                <div>{{ "profile.data.contract_price" | translate }}</div>
                <div class="faded">{{ "" }}</div>
            </div>
        </ng-container>

        <div class="divider"></div>

        <div class="fullwidth cellrow">
            <div>
                {{ "profile.data.change_password" | translate }}
            </div>
            <div class="primary pointer" (click)="togglePasswordReset()">
                {{ "base.modify" | translate }}
                <i class="pi pi-pencil"></i>
            </div>
        </div>

        <div class="divider"></div>

        <div class="fullwidth cellrow">
            <div>
                {{ "profile.data.delete_profile" | translate }}
            </div>
            <div class="primary pointer" (click)="toggleUserDelete()">
                {{ "profile.data.delete_profile" | translate }}
                <i class="pi pi-trash"></i>
            </div>
        </div>

        <div class="divider"></div>

        <div class="fullwidth cellrow">
            <div></div>
            <p-button label="{{ 'base.save' | translate }}" (click)="updateData()"></p-button>
        </div>
    </div>

    <p-dialog [(visible)]="deleteUserDialog" header="{{'profile.data.delete_profile' | translate}}" [modal]="true"
        [style]="{width:'450px'}">
        <div>{{ 'profile.delete_profile_before' | translate }}</div>
        <div>{{ 'profile.data.delete_profile_sure' | translate }}</div>
        <div class="text-red-700" *ngIf="deleteError != ''">{{ deleteError }}</div>
        <div style="height:20px"></div>
        <label for="delete-password">{{ 'profile.data.delete_password' | translate }}</label>
        <input type="password" id="delete-password" class="w-full" pInputText
            [placeholder]="'profile.data.delete_password_hint' | translate" [(ngModel)]="deletePassword"
            style="width: 100%">
        <ng-template pTemplate="footer">
            <button pButton class="p-button-text primary" label="{{ 'base.cancel' | translate }}"
                (click)="deleteUserDialog = false"></button>
            <p-button [disabled]="deletePassword == ''" label="{{ 'profile.data.delete_profile_confirm' | translate }}"
                (click)="confirmDelete()"></p-button>
        </ng-template>
    </p-dialog>

    <p-dialog [(visible)]="changePasswordDialog" header="{{'profile.data.change_password' | translate}}" [modal]="true"
        [style]="{width:'450px'}">
        <div class="text-red-700" *ngIf="changePasswordError != ''">{{ changePasswordError }}</div>
        <label for="change-password">{{ 'base.password' | translate }}</label>
        <input type="text" id="change-password" class="w-full" pInputText
            [placeholder]="'profile.data.delete_password_hint' | translate" [(ngModel)]="changePasswordPassword"
            style="width: 100%">
        <div style="height:20px"></div>
        <label for="change-password-confirm">{{ 'base.password2' | translate }}</label>
        <input type="text" id="change-password-confirm" class="w-full" pInputText
            [placeholder]="'profile.data.delete_password_hint' | translate" [(ngModel)]="changePasswordPasswordNew"
            style="width: 100%">
        <ng-template pTemplate="footer">
            <p-button [disabled]="changePasswordPassword == '' || changePasswordPassword != changePasswordPasswordNew"
                label="{{ 'base.save' | translate }}" (click)="changePassword()"></p-button>
        </ng-template>
    </p-dialog>
</div>