import { OAuthService } from 'angular-oauth2-oidc';
import { AuthConfig } from 'angular-oauth2-oidc';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable, lastValueFrom } from 'rxjs';
import { SystemService } from './system.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  //------------------------------------------------------------------------------------------------
  //Variables
  //------------------------------------------------------------------------------------------------

  private authConfig: AuthConfig;

  loginProgress: boolean;
  userData: any;
  userSub = new BehaviorSubject<any>({});
  errorMessage: string | null = null;
  verifyMessage: string | null = null;
  verifyType: string = 'success';

  emailForPasswordResetStartDialog: boolean;
  emailForPasswordResetEndDialog: boolean;
  emailForPasswordReset: string
  //------------------------------------------------------------------------------------------------
  //Constructor
  //------------------------------------------------------------------------------------------------

  constructor(
    private http: HttpClient,
    private oauthService: OAuthService,
    private router: Router,
    private systemService: SystemService
  ) { }

  //------------------------------------------------------------------------------------------------
  //Properties
  //------------------------------------------------------------------------------------------------

  public get isLoggedIn() {
    return this.oauthService.hasValidAccessToken();
  }

  public get accessToken() {
    return this.oauthService.getAccessToken();
  }

  //------------------------------------------------------------------------------------------------
  //Methods
  //------------------------------------------------------------------------------------------------

  async init() {
    this.authConfig = {
      issuer: environment.idp.issuer,
      redirectUri: environment.selfUrl,
      loginUrl: `${environment.idp.issuer}${environment.idp.loginUrl}`,
      logoutUrl: `${environment.idp.issuer}${environment.idp.logoutUrl}`,
      postLogoutRedirectUri: environment.selfUrl,
      clientId: environment.idp.client,
      responseType: 'password',
      showDebugInformation: true,
      tokenEndpoint: `${environment.idp.issuer}${environment.idp.loginUrl}`,
      dummyClientSecret: `${environment.idp.clientSecret}`,
    };
    await this.oauthService.configure(this.authConfig);
    await this.oauthService.setStorage(sessionStorage);

    if (this.isLoggedIn) {
      await this.getUserData();
    }
  }

  async login(username: string, password: string): Promise<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });
    this.loginProgress = true;
    this.errorMessage = null;
    this.verifyMessage = null;
    return this.oauthService
      .fetchTokenUsingPasswordFlow(username, password, headers)
      .then((response) => {
        this.getUserData();
        this.userData = response;
        this.router.navigate(['/calendar']);
      })
      .catch((error) => {
        this.errorMessage = error.error.error;
        return Promise.reject();
      })
      .finally(() => (this.loginProgress = false));
  }

  async getUserData() {
    return lastValueFrom(this.http.get(environment.apiUrl + 'api/auth/me'))
      .then((result: any) => {
        this.userData = result?.data;
        this.userSub.next(0);
      })
      .catch((error) => {
        this.userData = null;
        this.userSub.next(0);
        this.oauthService.logOut(true);
      });
  }

  getRooms() {
    return this.http.get(environment.apiUrl + 'api/rooms');
  }

  getReservationForDay(date, editId: any) {
    if (editId) {
      return this.http.get(
        environment.apiUrl + `api/reservations/reserved?day=${date}&edited_id=${editId}`
      );
    }

    return this.http.get(
      environment.apiUrl + `api/reservations/reserved?day=${date}`
    );
  }

  logout() {
    this.http
      .post(environment.apiUrl + environment.idp.logoutUrl, null)
      .subscribe(() => {
        this.userData = null;
        this.oauthService.logOut(true);
        this.router.navigate(['/login']);
        this.userSub.next(0);
      });
  }

  signUp(form: any): Observable<any> {
    return this.http.post(environment.apiUrl + 'api/register', form, {
      headers: { Accept: 'application/json', Connection: 'keep-alive' },
    });
  }

  deleteProfile(): Promise<any> {
    return lastValueFrom(
      this.http.delete(environment.apiUrl + 'api/auth/me', {
        headers: { Accept: 'application/json', Connection: 'keep-alive' },
      })
    );
  }

  changePassword(password: string, passwordConfirm: string): Promise<any> {
    return lastValueFrom(
      this.http.put(environment.apiUrl + 'api/auth/password', {
        password: password,
        password_confirmation: passwordConfirm,
      })
    );
  }

  resetPasswordViaEmail(): Promise<any> {
    return lastValueFrom(this.http.post(`${environment.apiUrl}${this.systemService.routes['api.send_forgot_password'].uri}`,
      {
        email: this.emailForPasswordReset
      }));
  }

  resetPassword(form: any): Promise<any> {
    return lastValueFrom(this.http.post(`${environment.apiUrl}${this.systemService.routes['api.reset_password'].uri}`, form));
  }

  updateData(data: any) {
    return lastValueFrom(
      this.http.put(environment.apiUrl + 'api/auth/me', data)
    );
  }

  getReservationForUser(date) {
    return this.http.get(
      environment.apiUrl + `api/auth/me/reservations/calendar?day=${date}`
    );
  }

  verifyEmail(id: string, hash: string) {
    return lastValueFrom(
      this.http.post(environment.apiUrl + `api/email/verify`, {
        id: id,
        hash: hash,
      })
    )
      .then((response: any) => {
        this.verifyType = 'success';
        this.verifyMessage = response.message;
      })
      .catch((error) => {
        this.verifyType = 'error';
        this.verifyMessage = error.error.error;
      });
  }
}
