import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageKeys } from 'src/app/constants/messageKeys';
import { AppBaseComponent } from 'src/app/core/pagebase/app.base.component';
import { AuthService } from 'src/app/services/auth.service';
import { MenuService } from 'src/app/services/menu.service';
import { ProgressMessageService } from 'src/app/services/progress.message.service';
import { SystemService } from 'src/app/services/system.service';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset.password.component.html',
})
export class ResetPasswordComponent implements OnInit, OnDestroy {

    //------------------------------------------------------------------------------------------------
    //Variables
    //------------------------------------------------------------------------------------------------

    messageKeys = MessageKeys;
    signUpSuccessDialog: boolean = false;

    form: FormGroup;

    private token: string;

    //------------------------------------------------------------------------------------------------
    //Constructor
    //------------------------------------------------------------------------------------------------

    constructor(
        private authService: AuthService,
        private menuService: MenuService,
        public systemService: SystemService,
        private messageService: ProgressMessageService,
        private translateService: TranslateService,
        private route: ActivatedRoute,
        private router: Router) {
    }

    //------------------------------------------------------------------------------------------------
    //Methods
    //------------------------------------------------------------------------------------------------

    ngOnInit() {
        this.menuService.showMenu = false;

        this.token = this.route.snapshot.queryParams['token'];

        this.form = new FormGroup({
            token: new FormControl(this.token, [Validators.required]),
            password: new FormControl('', [Validators.required]),
            password_confirmation: new FormControl('', [Validators.required]),
        });
    }

    ngOnDestroy() {
        this.menuService.showMenu = true;
    }

    submit() {
        if (this.form.valid) {
            const finalForm = Object.assign({}, this.form.value);

            if (finalForm.password !== finalForm.password_confirmation) {
                window.scroll({ top: 0, left: 0, behavior: 'smooth' });
                this.messageService.addError(MessageKeys.ResetPass, this.translateService.instant('resetPassword.notSame'));
                return;
            }

            this.authService.resetPassword(finalForm)
                .then(() => {
                    this.messageService.addSuccess(MessageKeys.ResetPass, this.translateService.instant('resetPassword.success'));
                }).catch(() => {
                    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
                    this.messageService.addError(MessageKeys.ResetPass, this.translateService.instant('error.unknown'));
                });
        } else {
            window.scroll({ top: 0, left: 0, behavior: 'smooth' });
            this.messageService.addError(MessageKeys.ResetPass,  this.translateService.instant('signup.markedFields'));
            return;
        }
    }
}
