<div class="contact-container">
  <div>
    <h2 class="mb-5">{{'legalnotice.title' | translate}}</h2>
    <p>{{'base.company_name' | translate}}: {{ settings.name }}</p>
    <p>
      {{'legalnotice.headquarters' | translate}}: {{ settings.addressZip }} {{ settings.addressCity }},
      {{ settings.addressLine }}
    </p>
    <p>
      {{'legalnotice.postal' | translate}}: {{ settings.mailingAddressZip }}
      {{ settings.mailingAddressCity }}, {{ settings.mailingAddressLine }}
    </p>
    <p>{{'base.vat_number' | translate}}: {{ settings.vatNumber }}</p>
    <p>{{'legalnotice.regNumber' | translate}}: {{ settings.registrationNumber }}</p>
    <p>{{'legalnotice.bankAccount' | translate}}: {{ settings.bankAccountNumber }}</p>
    <p>
      {{'base.email' | translate}}: {{ settings.representativeEmail1 }},
      {{ settings.representativeEmail2 }}
    </p>
    <p>{{'base.phone' | translate}}: {{ settings.representativePhone }}</p>
  </div>
</div>
<app-banner backgroundColor="#DBF5B0" [title]="'banner.checkOurRooms' | translate" bgCubesClass="banner-cubes3"
  [subtitle]="'banner.oneRehearsalArea2' | translate">
  <div>
    <button pButton class="button-beige" [label]="'base.rooms' | translate" [routerLink]="['/rooms']"></button>
  </div>
</app-banner>